import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';

//MUI and Icons Import
import { Box, Grid, Avatar, Typography, Rating, Button, Tabs, Tab, Stack, IconButton, Alert, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, OutlinedInput, InputAdornment, FormHelperText } from '@mui/material';
import StarIcon from '@mui/icons-material/Star'
import CloseIcon from '@mui/icons-material/Close'
import profileImage from "../../assets/images/candidate-profile-image.png"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

//Components & data Import
import BasicInformation from './../AdminDashboard/requestIdDrawerComponents/BasicInformation';
import Resume from './../AdminDashboard/requestIdDrawerComponents/Resume';
import InterviewFeedback from './../AdminDashboard/requestIdDrawerComponents/InterviewFeedback';
import Comments from './../AdminDashboard/requestIdDrawerComponents/Comments';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import { Add, Save, WarningAmber } from "@mui/icons-material";

let tempBI = BasicInformationData

localStorage.setItem("tempBI", JSON.stringify(tempBI))

//Tab Panels Code
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


//Component code starts here
const AddAllowedIPDrawer = ({ id, parentId, drawerHeading, candidateDetailsModel }) => {
    const [currentIp, setCurrentIp] = useState(false)
    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        console.debug("close : parentId ", parentId)
        dispatch(closeDrawer(parentId));
    };
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    console.debug("candidateDetailsModel", id)
    function onClickCurrentIpHandler() {
        setCurrentIp(!currentIp)
    }
    return (<>
        <Stack sx={{ backgroundColor: "var(--color-lightblue)", mb: 'var(--equal-hw1)', }}>
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Box pl='1rem' display='flex'>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >{drawerHeading} </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                            <CloseIcon sx={{ color: 'var(--color-black)' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>

        </Stack>
        <Box sx={{ height: "90vh", overflowY: "auto" }}>
            <Box sx={{ px: 3 }}>
                <Alert severity="error" icon={<WarningAmber fontSize="inherit" />}  >Ensure that the IP address you provide is a static IP address. Since the dynamic IP addresses change and you might get locked out of your account.</Alert>
            </Box>
            <Box sx={{ width: 1 }}>
                <Box component="form" p={2} sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper   {...candidateDetailsModel.model} />
                </Box>
                <Box component="form" p={3} >
                    <FormControl sx={{ width: "100%" }}>
                        <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="female" control={<Radio />} label="Add current IP address" onClick={onClickCurrentIpHandler} />
                            {currentIp && <Typography fontWeight="var(--font-weight-5)" variant="h7" sx={{ pl: 3.5, pb: 3 }}>The IP address of this device connected network is 45.122.120.215</Typography>}
                            <FormControlLabel value="male" control={<Radio />} label="Add static IP address" />
                            <>
                                <Typography fontWeight="var(--font-weight-5)" variant="h7" sx={{ pl: 3.5 }}>You can add any valid static  IP  address manually  here</Typography>
                                <Box>
                                    <FormControl sx={{ m: 1, pl: 3 }} variant="outlined">
                                        <FormHelperText id="outlined-weight-helper-text" sx={{ pb: 1, m: 0 }}>IP Address</FormHelperText>
                                        {/* <FormControlLabel value="other" label="Add IP range" /> */}
                                        <Box >
                                            <OutlinedInput
                                                id="outlined-adornment-weight"
                                                aria-describedby="outlined-weight-helper-text"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                                sx={{ width: '45ch', }}
                                                endAdornment={<InputAdornment position="end">1/12</InputAdornment>}
                                            />
                                            <IconButton>
                                                <Add />
                                            </IconButton>
                                        </Box>
                                    </FormControl>

                                </Box>
                            </>
                            <FormControlLabel value="other" control={<Radio />} label="Add IP range" />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box display={"flex"} sx={{ p: 2 }}>
                    <Button variant="contained" sx={{ mx: 1 }} ><Typography variant="body2">ADD</Typography></Button>
                    <Button variant="outlined" sx={{ mx: 1 }} onClick={handleDrawerClose} ><Typography variant="body2">CANCEL</Typography></Button>
                </Box>
            </Box>
        </Box>
    </>)
}

export default AddAllowedIPDrawer;