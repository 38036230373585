import React, {useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';

//MUI and Icons Import
import { Box, Grid, Avatar, Typography, Rating, Button, Tabs, Tab, Stack, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star'
import CloseIcon from '@mui/icons-material/Close'
import profileImage from "../../assets/images/candidate-profile-image.png"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

//Components & data Import
import BasicInformation from './../AdminDashboard/requestIdDrawerComponents/BasicInformation';
import Resume from './../AdminDashboard/requestIdDrawerComponents/Resume';
import InterviewFeedback from './../AdminDashboard/requestIdDrawerComponents/InterviewFeedback';
import Comments from './../AdminDashboard/requestIdDrawerComponents/Comments';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import { Save } from "@mui/icons-material";
import ApiService from "../../utils/ApiService";
import { setUserInfo } from "../../features/userInfoStore/userSlice";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { LoadingButton } from "@mui/lab";

let tempBI = BasicInformationData

localStorage.setItem("tempBI", JSON.stringify(tempBI))

//Tab Panels Code
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//Component code starts here
const AddToolsDrawer = ({ id, parentId, drawerHeading, }) => {
    console.debug("close : id ", id)
    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        console.debug("close : parentId ", parentId)
        dispatch(closeDrawer(parentId));
    };
    const userData = useSelector((state) => state?.user?.userListName);
    const toolList = useSelector(state => state?.toolsList?.tools)
    const userToolsData = useSelector((state) => state?.drawer?.data); // {} by default
    const [value, setValue] = React.useState(0);
    const [showErrors, setShowErrors] = React.useState(0);

    const [loading,setLoading]=useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const allowedCharactersRegex = /^[a-zA-Z]+$/;

    const toolsDetailsModel = [{label: 'Tools',type: "autoComplete",data: toolList,freeSolo: true,value: userToolsData.map(v => v.tool),sx: { gridColumn: 'span 6', m: 1 },
        validators: [{ name: 'required'},{ name: 'pattern', regex: /^(?!\s)[a-zA-Z0-9\s_\-]{2,}(?!\s)$/, errorMessage: 'Field must only contain only contain letters, _, -, and spaces' }],multiple: true}];

    // let toolsDetailsModel = [
    //     { label: 'Tools', type: "autoComplete", data: toolList, freeSolo: true, value: '', sx: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }], multiple: true },
    // ];
    let toolsDetailsHelper = new FormHelper({ model: toolsDetailsModel, id: 'candidateDetails' });

    const [candidateDetailsHelperData, setCandidateDetailsHelperData] = useState(toolsDetailsModel);

    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }

    const addContactDetail = () => {
        let dataCandidateDetalsHelper = candidateDetailsHelperData.reduce((prev,curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);
        console.log({dataCandidateDetalsHelper})
        
        var error = false;
        var errorMessage = false;
       
        for (let i = 0; i < candidateDetailsHelperData.length; i++) {
            for (let j = 0; j < candidateDetailsHelperData[i]?.validation?.length; j++) {
                var res = candidateDetailsHelperData[i]?.validation[j](candidateDetailsHelperData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    break;
                }
            }
        }
        if(error){
            setShowErrors(showErrors + 1);
            return;
        }

        let formData = new FormData();

        dataCandidateDetalsHelper['Tools'].map((tool, index) => {
            formData.append('tools[' + index + ']', tool);
        })
        setLoading(true);
        ApiService.postAdopter('updateUser', formData, userData?.userId).then((res) => {
            console.log({ res })
            if(res){
                handleDrawerClose()
                getUserData();
                if(res.status==200){
                    dispatch(setSnackBar({ dataLoad: true, message: "Data added successfully", severity: "success" }))
                    setLoading(false)
                }
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            setLoading(false)
        })
    }

    return (<>
        <Stack sx={{ backgroundColor: "var(--color-lightblue)", mb: 'var(--equal-hw1)', }}>
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Box pl='1rem' display='flex'>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Update Tools </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                            <CloseIcon sx={{ color: 'var(--color-black)' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
        <Box sx={{ height: "90vh", overflowY: "auto" }}>
            <Box sx={{ width: 1 }}>
                <Box component="form" p={2} sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => {console.log("Hind Ki Chadar",{data});setCandidateDetailsHelperData(data)}} {...toolsDetailsHelper.model} />
                </Box>
                <Box display={"flex"} sx={{ p: 2 }}>
                <LoadingButton
                        color="primary"

                        loading={loading}
                        loadingPosition="start"
                        startIcon={<Save />}
                        variant="contained"
                        // disabled={disableSumbit}
                        onClick={addContactDetail}
                    >
                        <span>UPDATE</span>
                    </LoadingButton>
                    {/* <Button variant="contained" sx={{ mx: 1 }} onClick={addContactDetail} ><Typography variant="body2">ADD</Typography></Button> */}
                    <Button variant="outlined" sx={{ mx: 1 }} onClick={handleDrawerClose} ><Typography variant="body2">CANCEL</Typography></Button>
                </Box>
            </Box>
        </Box>
    </>)
}

export default AddToolsDrawer;