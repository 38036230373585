import { default as axios } from 'axios';
import { getAppToken, getSelfToken } from "../_helper/secureToken"
const BASE_URL = process.env.REACT_APP_BASEURL;
const MAIL_URL = process.env.REACT_APP_USERINFO;
const HELPDESK_URL = process.env.REACT_APP_HELPDESK;
// const token="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYzJhNTA3YTBhMjI0MjQ1YmY2MDRhOTVjYjZiYjMxNzkyZTM4ODFlMGU2ZmFlODA5MDBmNmZjMzE4ODUwY2M5NGI5NTcyZTNmYjhkYTdmYTAiLCJpYXQiOjE3MDc4ODg0MDEuODg4NzUxLCJuYmYiOjE3MDc4ODg0MDEuODg4NzU0LCJleHAiOjE3Mzk1MTA4MDEuODgzNTIzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ZKjiL6xEhM9JJYtG0Br-_69ryoPzaVQiVCVpxu94TvvdAMOBY1TLM9awMVY6lGsz8pqun5XevF_Xb0Fvwk9Lg0sT0jVFh6n2ymo05Kjc7vm6M_ojYtaKzLypcQYwEnnc9RFvytOHD08M57LWT8Ei31LKuGL1QuHwMWhdsqDfLSy3UF9HHQth008I13VIjzY5jC2Su0EifvfGRsZ9KJHJxD_ZKeSDugT1Q1xWYJc9ZQF27eCmK5nSzX-mNKeohK_oLkSs2M80cJcI9DJ5c5-Pql3qCha9blYIRX9se2dOz7U3_dN8n_GS5V-UdNg7-EIJOaRgqCtNP-ArIuVYOX3V73yasVsvCv84MWdm7YnnnzzFrS41pz62UzuY4XUczuljTj2tCrNszn24h-KPUC-aJHteOuSHspV-yUqfkctHvLjMyc0KZUcBuYH_OBx1noPktRnvJ9mB4I2u0oW6wCfru6RXrG58aJNgoGfJxtwPXrAr53JjpZdbmlph-ZZXyqaXtnkmIDXkmdy-Ix7AYKjh6S8PNyyfiE6kQyqPSIPjOwgTHXrCfiIKwvoM4E37F-euCksT5tlbbsr3iVFbIA0v9gSjgIBo21QG5P9Ck585kxWqJsCTPp4TtvPQ5iC4edpz6g0Z2oDvmxdkLC1-2XPujLJk9Ljtk0qxOxJ6FGtbtZk"
// const token ="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNjM2OTA1MzMxZWQyOTliMGM1YjQ3ZDhmNTJlZThkMjRjNDBiYmQ4YjgxMmI3ODZmNGViZTMyNDdkMjY1ZDVkMjEzZGJkNWVjZTZlMmYwM2YiLCJpYXQiOjE3MDc4ODk4NjMuMDc2MjMxLCJuYmYiOjE3MDc4ODk4NjMuMDc2MjM0LCJleHAiOjE3Mzk1MTIyNjMuMDcxNjQsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.GEUnYeC1Nx-FD2RRn9rURe4t1YDBJ0N960J50_TaieEoQ7Bu4HpG3lys-qfExGxSrNeQQVpm1QdVt3QWlGRMCiEd0Nec9WMjUVng-dX57PN-gGnI0aYRPtSjh7ct1cYfy6EGv-IjNclUZBhxOungDaPX5EvHNKVYwZ29k3QDYiWZXEVBhssTFp6yxS42gAYyEBQv7g4TEufMJCtL4A0H-FxsahwEN-PVZ1iL-e9R_innnk29HFMw9JS0lcGkYKpPVfJX7vbO__iS0flbFDCs3vfhjXjXAzZbDT8S9by5TyteSdSvyQKkhA_m2TX3gl6_cVY3kefNrmf154V5H_ulT8LzAQ1Aq5kR4tgwZdZ94sW742UC29Hw8Lj4LRGIv6diyarWkEBhuQFE4hQVrMovNAd1Sk67CLCrnsazi5HyRK_76K0fNKgs3MEQeUp_lmrScX8xQFIeMA1LfAP2tybX9tbtn0KMcQCaEGzjuJ-q7ArGLXdkAvlvCw9A4Srnpf8ppWneookn-VvwFQUgCQA4_oGWyi9idqPOrHs7zEZlbQ9CCK9w7cuuOPKqdwE4Nf-tCLi_LjiCIXyyxcvsBDPjQcTH6Agkis_rSgd69hHQNOzrUVdeckfe7_X85x9Az0bTNTOx0_3oHmyXL10bK866rQCd1fvZsEqlmMAqCeBP_e8"

class APIService {
  constructor(baseURL, mailURL, helpdeskURL) {
    // this.baseURL = baseURL || BASE_URL;
    // this.api = axios.create({
    //   baseURL: this.baseURL
    // });

    // Define your endpoints here
    this.endpoints = {
      userDetails: "api/v1/userinfo",
      comapnyLog: "api/v1/company/1",
       //-----------Add Address ------------
       userAddress: "api/v1/user-address",
       userAddressUpdate: "api/v1/user-address-validation",

      //-----------Add Contact ------------
      userContact: "api/v1/user-contact",
      userContactUpdate: "api/v1/user-contact-validation",


      //-----------Add Email ------------
      userEmail: "api/v1/user-email",
      userEmailUpdate: "api/v1/user-email-validation",

      //-----------Add Family ------------
      userFamily: "api/v1/user-familyinfo",
      userFamilyUpdate: "api/v1/user-familyinfo-validation",

      //-----------Add Education ------------
      userEducation: "api/v1/user-education",
      userEducationUpdate: "api/v1/user-education-validation",

      //-----------Add Certificate ------------
      userCertificate: "api/v1/certificate",
      userCertificateUpdate: "api/v1/certificate-validation",

       //-----------Add Employment ------------
       userEmployement: "api/v1/user-employement",
       userEmployementUpdate: "api/v1/user-employement-validation",

      //-----------Add Employment ------------
      userDocumnets: "api/v1/user-documents",
      userDocumnetsUpdate: "api/v1/user-documents-validation",

      //-----------Award Listing------------

      awardList: "api/v1/award",
      userAwardList: "api/v1/award-user",
      accoladesList: "api/v1/accolades",
      promotionList: "api/v1/promotion",


      // -----Our People-----------
      associateSpotlight: "api/v1/associates-feedback",
      stories: "api/v1/recipes",
      announcement: "api/v1/notice",
      newJoiners: "api/v1/user-joining",
      wedding: "api/v1/user-weddings",
      supportDirectory: "api/v1/contact-directory",

      // ----------User-------------
      user: "api/v1/users",
      policyDocument: "api/v1/policy-documents",
      recentDocument: "api/v1/recent-document",
      comments: "api/v1/comments",

      // ----------Newsletter-------------
      leaderSpeak: "api/v1/leader-speak",
      social: "api/v1/social",
      events: "api/v1/events",

      // ----------Celebration-------------
      birthAnnouncement: "api/v1/childs",
      birthday: "api/v1/user-birth",
      workAnniversary: "api/v1/user-work-anniversary",

      // ----------Set-Up-------------
      company: "api/v1/company",
      menu: "api/v1/menu-setting",
      pageWidget: "api/v1/page-widget",
      carousel: "api/v1/carousel",
      holidays: "api/v1/holidays",
      certificate: "api/v1/certificate",
      generalSetting: "",



      // ----------Report-------------
      commentReport: "api/v1/comment-report",
      postReport: "api/v1/post-report",


      // ----------Suggesstion-------------
      suggestion: "api/v1/suggestion-responses",

      // ----------News-------------
      news: "api/v1/news",

      // ----------Helpdesk-------------
      faq: "/api/v1/faq-ques-ans",
      tickets: "api/v1/ticket",


      menuSetting: "api/v1/menu-setting",
      myReferral: "api/v2/application",
      applyJob: "api/v1/application",
      tickets: "api/v1/ticket",


      // ----------- Filter Category -------
      userInfo: "api/v1/userinfo",
      // FeedList: 'api/v1/posts',
      // FeedAnnouncements: 'api/v1/notice',
      // LeaderSpeak: 'api/v1/leader-speak',
      // CompanyWithAHeart: 'api/v1/social',
      // Events: 'api/v1/events',
      // Social: 'api/v1/social',
      // Awards: "api/v1/award-user",
      // Birthdays: "api/v1/user-birth",
      // Joiners: "api/v1/user-joining",
      // Promotions: "api/v1/promotion",
      // Accolades: "api/v1/accolades",
      // WorkAniversary: "api/v1/user-work-anniversary",
      // birthAnnouncement: "api/v1/childs",
      // Stories: "api/v1/recipes",
      // Weddings: "api/v1/user-weddings",
      // AssociateSpotlight: "api/v1/associates-feedback",
      // jobFilter: "api/v1/jobs/new-filter",

      //-----Get----///
      // savePost: 'api/v1/posts',
      getReaction: 'api/v1/postsreaction',

      //-------delete--//
      deletePost: 'api/v1/posts',
      deletePostsReaction: 'api/v1/postsreaction',

      //---------save post-----//
      savePostUser: 'api/v1/save-post',
      saveFavoriteFeeds: 'api/v1/favorite-feeds',

      //---------unsave post-----//
      unsavePostUser: 'api/v1/unsave-post',
      unsaveFavoriteFeeds: 'api/v1/favorite-feed-remove',

      //------post-//
      getPostsReaction: 'api/v1/postsreaction',
      submitComment: 'api/v1/feed-comments',

      //--------update-------------//
      updateReaction: 'api/v1/postsreaction',
      // -------reaction ------- ///
      reactPost: 'api/v1/postsreaction',
      reactPostPages: 'api/v1/reaction',

      // ------- Org Chart -------//
      orgUsers: 'api/v1/users/hierarchy',
      orgDepartment: 'api/v1/department',
      orgDepartmentUser: 'api/v1/users',
      branch: 'api/v1/branch',
      skills: 'api/v1/skills',
      jobs: 'api/v1/jobs/filter',
      getAllCity: 'api/v1/city',
      getAllCountry: 'api/v1/country',
      jobPriority: 'api/v1/job-priority',
      contactDirectory: 'api/v1/contact-directory',
      favouriteJob: 'api/v1/favorite-jobs',
      unsaveJob: 'api/v1/favorite-jobs-remove',
      jobDetails: 'api/v1/job',
      usersFilter: 'api/v1/users-filter',

      //  ------delete image edit post -------
      deleteImage: 'api/v1/images',
      deleteComment: 'api/v1/feed-comments',
      updateComment: 'api/v1/feed-comments',

      // --------- Referal ---------------///
      referalJob: 'api/v1/job',
      importantLinks: 'api/v1/link-type',

      // --------- Saved Posts ---------------///
      savedPosts: 'api/v1/saved-posts',
      favoriteFeeds: 'api/v1/favorite-feeds',
      HiddenPost: 'api/v1/hide-post',
      bankDetail:'api/v1/user-bank-details',
      bankDetailUpdate: 'api/v1/user-bank-details-validation',

      // ----------- Allowed File Extensions -------------
      allowedFileExtensions: 'api/v1/file-extension',
      uploadFileSize: 'api/v1/upload-file-size',
      resetPassword: 'api/v2/reset-password',
      deviceSignIn: 'api/v2/get-user-token',


      // ---------- MyAccount Single Select APis -----------
      city: 'api/v1/city',
      state: 'api/v1/state',
      country: 'api/v1/country',
      tools: 'api/v1/tool',
      tools: 'api/v1/tool',
      skills: 'api/v1/skills',

      // ---------- Update User --------------
      updateUser: 'api/v1/user-update-userinfo',

    };

    this.api = axios.create({
      baseURL: baseURL,
      withCredentials: true,
    });
    this.apiMail = axios.create({
      baseURL: mailURL
    });
    this.apiHelpdesk = axios.create({
      baseURL: helpdeskURL
    });
  }
  async getRequest(apiInstance, endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await apiInstance.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() },
      });
      return response;
    } catch (error) {
      if(error?.response?.status == 401){
        window.location.href = '/logout';
      }
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', error);
    }
  }

  async get(endpointKey, params = {}, searchParam = null) {
    return await this.getRequest(this.api, endpointKey, params, searchParam);
  }

  async getApi(endpointKey, params = {}, searchParam = null) {
    return await this.getRequest(this.apiMail, endpointKey, params, searchParam);
  }
  async getHelpdesk(endpointKey, params = {}, searchParam = null) {
    return await this.getRequest(this.apiHelpdesk, endpointKey, params, searchParam);
  }
  async post(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', { error });
    }
  }
  async postHelpdesk(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // data.app = getAppToken();
      const response = await this.apiHelpdesk.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', error);
    }
  }

  async put(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // data['app'] = getAppToken();
      params.app = getAppToken();

      const response = await this.api.put(endpoint, params, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', { error });
    }
  }

  async postAdopter(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      endpoint = endpoint + '?app=' + getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error( error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', { error });
    }
  }

}



export default new APIService(BASE_URL, MAIL_URL, HELPDESK_URL);
