import React, { useState,useEffect } from 'react'
import { Box, Button, Typography, Grid, Stack, CardHeader, Chip, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import FormHelper from '../../form/FormHelper';
import { Add, Delete, Edit, } from '@mui/icons-material';
import SharedDrawer from '../../features/drawers/Drawer';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AddEmploymentDrawer from '../../components/AdminDrawers/AddEmployementDrawer';
import AddSkillsDrawer from '../../components/AdminDrawers/AddSkillsDrawer';
import AddToolsDrawer from '../../components/AdminDrawers/AddToolsDrawer';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { openDialog, closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const EmployementDetail = ({ readOnly }) => {
    const userEmploymentDetails = useSelector((state) => state?.user?.userListName?.userEmployment);
    console.log("y1",userEmploymentDetails)
    const userSkillsDetails = useSelector((state) => state?.user?.userListName?.skills);
    const userToolsDetails = useSelector((state) => state?.user?.userListName?.toolsUser);
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [currentEditEmploymentIndex, setCurrentEditEmploymentIndex] = useState();

    const [hovered, setHovered] = useState(false);
    const dispatch = useDispatch();
    const openEmploymentDrawer = () => {
        setCurrentEditEmploymentIndex(-1)
        dispatch(openDrawer('employment'));
    }
    const openEditEmploymentDrawer = (data, index) => {
        dispatch(openDrawer({ id: 'employment', data }));
        setCurrentEditEmploymentIndex(index);
    }
    const openSkillsDrawer = () => {
        // dispatch(openDrawer({ id: 'education', data }));
        dispatch(openDrawer({id: 'skills', data: userSkillsDetails}));
    }
    const openToolsDrawer = () => {
        // dispatch(openDrawer('tools'));
        dispatch(openDrawer({id: 'tools', data: userToolsDetails}));
    }

    const openDeleteDialog = (type, id, index) => {
        setCurerentItemToDelete({ type, id, index });
        dispatch(openDialog('deleteDialog'));
    }

    const deleteHandler = () => {
        if (!curerentItemToDelete.type) {
            return;
        }
        switch (curerentItemToDelete.type) {
            case 'userEmploymentDetails': console.log("Look here", { userEmploymentDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
            case 'userSkillsDetails': console.log("Look here", { userSkillsDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
            case 'userToolsDetails': console.log("Look here", { userToolsDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
        }
    }
    useEffect(function () {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Employment & Skills</Typography>
                    </Box>
                </Stack>
                <Card sx={{ mb: 3, p: 1, pb: 0 }}>
                   
                    <CardContent sx={{ p: 0 }} >
                        <List >
                            {userEmploymentDetails?.map((data, index) => <ListItem onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)} sx={{ p: 0, mb: 3 }} key={data.id} >
                                <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                    <ListItemText>
                                    <Box sx={{my:1}}><Typography variant="h6" color="text.primary">Employement {index + 1}</Typography> </Box>
                                        <Box sx={{}}>
                                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ p: 0 ,width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)"}}>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Job Title
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {data.department}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                       Job Type
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {data.designation}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                       Company Name
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {data.previousCompanyName}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Location
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                    {data.officeAddress}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* <Box>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">Job Title</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.previousCompanyName}</Typography>
                                                </Box>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.previousCompanyName}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.fromDate && data.fromDate - data.toDate && data.toDate}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.officeAddress}</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.department}</Typography> */}
                                            {/* </Grid> */}
                                            {/* <Grid item xs={2} sm={4} md={4} >
                                                <Stepper orientation="vertical">
                                                    <Step>
                                                        <StepLabel startIcon={<Circle />}>edfeef</StepLabel>

                                                    </Step>
                                                    <Step>
                                                        <StepLabel>fefe</StepLabel>
                                                    </Step>

                                                </Stepper>
                                            </Grid> */}
                                        </Grid>
                                        </Box>
                                    </ListItemText>
                                    {/* {hovered && (
                                        <Box alignSelf={"center"}>
                                            <Button ><Edit onClick={() => { openEditEmploymentDrawer(data, index) }} /></Button>
                                            <Button onClick={() => openDeleteDialog('userEmploymentDetails', data.id, index )} ><Delete /></Button>
                                        </Box>
                                        )} */}
                                </ListItemButton>
                            </ListItem>)}

                        </List>

                        <Box textAlign={"center"}>
                            <Button startIcon={<Add />} onClick={openEmploymentDrawer}> ADD EMPLOYMENT</Button>
                        </Box>
                    </CardContent>
                    <SharedDrawer id="employment">
                        <AddEmploymentDrawer parentId='employment' currentEditEmploymentIndex={currentEditEmploymentIndex} />
                    </SharedDrawer>
                </Card>
                <Card sx={{ mb: 3, p: 3, pb: 0 }}>
                    <CardHeader
                        title={<Typography variant='h6'>Skills</Typography>}
                    />
                    <CardContent sx={{ p: 0 }}>
                        {userSkillsDetails?.map((data) => <Chip label={data.skill} sx={{ m: 2 }} />)}
                        <Box textAlign={"center"}>
                            <Button startIcon={<Add />} onClick={openSkillsDrawer}> UPDATE SKILLS</Button>
                        </Box>
                    </CardContent>
                    <SharedDrawer id="skills" >
                        <AddSkillsDrawer parentId='skills' />
                    </SharedDrawer>
                </Card>
                <Card sx={{ mb: 3, p: 3, pb: 0 }}>
                    <CardHeader
                        title={<Typography variant='h6'>Tools</Typography>}
                    />
                    <CardContent sx={{ p: 0 }}>
                        {userToolsDetails?.map((data) => <Chip label={data.tool} sx={{ m: 2 }} />)}
                        <Box textAlign={"center"}>
                            <Button startIcon={<Add />} onClick={openToolsDrawer}> UPDATE TOOLS</Button>
                        </Box>
                    </CardContent>
                    <SharedDrawer id="tools" >
                        <AddToolsDrawer parentId='tools' />
                    </SharedDrawer>
                </Card>
                <SharedDialog id="deleteDialog">
                    <Box pb="1rem">
                        <DialogTitle id="alert-dialog-title">
                            {"Delete"}
                        </DialogTitle>
                        <DialogContent sx={{ padding: '16px 24px' }}>
                            <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                This will be deleted forever and you won't be able to restore it.
                                {/* {methodsAndState.dynamicDeleteMessage} */}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: '16px 24px' }}>
                            <Button onClick={() => dispatch(closeDialog('deleteDialog'))}>
                                CANCEL
                            </Button>
                            <Button onClick={deleteHandler} color='primary' variant="contained" >YES, DELETE</Button>
                        </DialogActions>
                    </Box>
                </SharedDialog>
            </Box>
        </Box >
    )
}

export default EmployementDetail