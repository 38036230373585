import React, {useEffect} from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { publish } from '../helper/publisher.js';
import Autocomplete from '@mui/material/Autocomplete';

const InputSingleSelect = ({label,model,chainUpdatedData,showErrors,modelKey,validation,id,type,sx={}, change = () => {}, value, data = [],editMode, version, ...props}) => {
console.debug("GenderValue",value)
  const [selectValue, setSelectValue] = React.useState(value);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  useEffect(() => {
    // console.log("Ankur Ka Data", {showErrors})
    if(showErrors){
      var error = false;
      var errorMessage = false;
      for( let i = 0; i < validation?.length; i ++){
        let res = validation[i](selectValue);
        if(res.error){
          error = res.error;
          errorMessage = res.errorMessage;
          break;
        }
      }
      setError(error);
      setErrorMessage(errorMessage);
    }
  }, [showErrors]);

  const onClickTextValue = () =>{
  }

  const onChangeTextValue = (e) => {
    
    setSelectValue(e.target.value);
    model[modelKey].value = e.target.value;
    let error = false;
    let errorMessage = false;
    
    for( let i = 0; i < validation?.length; i ++){
      let res = validation[i](e.target.value);
      if(res.error){
        error = res.error;
        errorMessage = res.errorMessage;
        break;
      }
    }
    setError(error);
    setErrorMessage(errorMessage);
    change(e.target.value);

    /*PUBLISHING DATA TO THE TOUCHED HELPER */
    publish({data: model, id:'touched' + id});
    
    /*PUBLISHING DATA TO THE ACTIVETOUCH HELPER */
    publish({data: model[modelKey], id:'activeTouch' + id});

    /*PUBLISHING DATA TO THE FILLED HELPER */
    publish({data: error, id:'filled' + id});
  }

  console.log("Found Data", {data});

  return (
    <>
      <FormControl sx={sx}  error={error}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          key={version + modelKey}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={selectValue}
          onClick={onClickTextValue}
          onChange={onChangeTextValue}
          {...props}
          inputProps={{ readOnly: (editMode == false) } }
        >
          {data.map((v,i) => (<MenuItem key={i} value={v.value}>{v.label}</MenuItem>))}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </>
  )
}

export default InputSingleSelect