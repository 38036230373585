import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Grid, Stack, CardHeader, List, ListItem, ListItemButton, Link } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import { Add, ArrowForward, Delete, Edit, } from '@mui/icons-material';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AddEducationDrawer from '../../components/AdminDrawers/AddEducationDrawer';
import AddCertificateDrawer from '../../components/AdminDrawers/AddCertificateDrawer';
import SharedDrawer from '../../features/drawers/Drawer';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { openDialog, closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const EducationCertificate = ({ readOnly }) => {
    const dispatch = useDispatch();
    const [hardRefresh, setHardRefresh] = useState(true);
    const userEducationDetails = useSelector((state) => state?.user?.userListName?.userEducation);
    const userCertificationDetails = useSelector((state) => state?.user?.userListName?.certificate);
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [hoveredItems, setHoveredItems] = useState(Array(userEducationDetails?.length).fill(false));
    const [hoveredCertificateItems, setHoveredCertificateItems] = useState(Array(userCertificationDetails?.length).fill(false));
    const [currentEditEducationIndex, setCurrentEditEducationIndex] = useState();
    const [currentEditCertificateIndex, setCurrentEditCertificateIndex] = useState();

    console.debug("userCertificationDetails", userCertificationDetails)
    useEffect(() => {
        if (!hardRefresh) {
            setHardRefresh(true);
        }
        window.scrollTo(0, 0);
    }, [hardRefresh])

    const openCertificateDrawer = () => {
        setCurrentEditCertificateIndex(-1);

        dispatch(openDrawer('certificate'));
    }
    const openEducationDrawer = () => {
        setCurrentEditEducationIndex(-1);
        dispatch(openDrawer('education'));

    }
    const openEditEducationDrawer = (data, index) => {
        dispatch(openDrawer({ id: 'education', data }));
        setCurrentEditEducationIndex(index);
    }
    const openEditCertificateDrawer = (data, index) => {
        dispatch(openDrawer({ id: 'certificate', data }));
        setCurrentEditCertificateIndex(index);
    }
    const openDeleteDialog = (type, id, index) => {
        setCurerentItemToDelete({ type, id, index });
        dispatch(openDialog('deleteDialog'));
    }

    const deleteHandler = () => {
        if (!curerentItemToDelete.type) {
            return;
        }
        switch (curerentItemToDelete.type) {
            case 'userEducationDetails': console.log("Look here", { userEducationDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
            case 'userCertificationDetails': console.log("Look here", { userCertificationDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
        }
    }

    const handleListItemMouseLeave = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = false;
        setHoveredItems(newHoveredItems);
    };
    const handleListItemMouseEnter = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = true;
        setHoveredItems(newHoveredItems);
    };

    const handleCertificateListItemMouseLeave = (index) => {
        const newCertificateHoveredItems = [...hoveredCertificateItems];
        newCertificateHoveredItems[index] = false;
        setHoveredCertificateItems(newCertificateHoveredItems);
    };
    const handleCertificateListItemMouseEnter = (index) => {
        const newCertificateHoveredItems = [...hoveredCertificateItems];
        newCertificateHoveredItems[index] = true;
        setHoveredCertificateItems(newCertificateHoveredItems);
    };

    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Education & Certifications</Typography>
                    </Box>
                </Stack>
                <Card sx={{ mb: 3 }}>
                    <CardHeader
                        title={<Typography variant='h6' fontWeight="var(--font-weight-5)" sx={{ px: 2 }}>Educational Details</Typography>}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <List sx={{ pt: 0 }}>
                            {userEducationDetails?.map((data, index) => <ListItem
                                onMouseEnter={() => handleListItemMouseEnter(index)}
                                onMouseLeave={() => handleListItemMouseLeave(index)}
                                key={index}
                            >
                                <ListItemButton >
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        <Grid item xs={2} sm={4} md={4} >
                                            <Typography variant='body2' fontWeight="var(--font-weight-5)">{data.degree}</Typography>
                                            <Typography variant='caption' fontWeight="var(--font-weight-4)">{data.universityName}</Typography><br />
                                            <Typography variant='caption' fontWeight="var(--font-weight-4)">Percentage/CGPA :{data.CGPA}</Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} >
                                            <Typography variant='body2' fontWeight="var(--font-weight-5)">{data.fromYear} - {data.toYear}</Typography>
                                            <Typography variant='caption' fontWeight="var(--font-weight-4)">{data.fieldOfStudy}</Typography><br />
                                        </Grid>
                                        {/* {hoveredItems[index] && (
                                        <Grid item xs={2} sm={4} md={4} textAlign={"end"}>
                                            <Box alignSelf={"center"}>
                                                <Button><Edit onClick={() => { openEditEducationDrawer(data, index) }} /></Button>
                                                <Button onClick={() => openDeleteDialog('userEducationDetails', data.id, index )} ><Delete /></Button>
                                            </Box>
                                        </Grid>)} */}
                                    </Grid>
                                </ListItemButton>
                            </ListItem>)}

                        </List>
                        <Box textAlign={"center"}>
                            <Button startIcon={<Add />} onClick={openEducationDrawer}> ADD EDUCATION DETAILS</Button>
                        </Box>
                    </CardContent>
                </Card>
                <Card >
                    <CardHeader
                        title={<Typography variant='h6' fontWeight="var(--font-weight-5)" sx={{ px: 2 }}>Certificate</Typography>}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <List sx={{ pt: 0 }}>
                            {userCertificationDetails?.map((data, index) => <ListItem
                                onMouseEnter={() => handleCertificateListItemMouseEnter(index)}
                                onMouseLeave={() => handleCertificateListItemMouseLeave(index)}
                                key={data.id}
                            >
                                <ListItemButton >
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        <Grid item xs={2} sm={4} md={4} >
                                            <Typography variant='body2' fontWeight="var(--font-weight-5)">{data.title}</Typography>
                                            {/* <Typography variant='caption' fontWeight="var(--font-weight-4)">University of Oxford, England</Typography><br /> */}
                                            <Typography variant='caption' fontWeight="var(--font-weight-4)">{data.description}</Typography><br />
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} >
                                            {/* <Typography variant='body2' fontWeight="var(--font-weight-5)">Issued on: Oct 2023</Typography> */}
                                            <Typography variant='overline' fontWeight="var(--font-weight-5)" sx={{ mt: 1 }}><Link href={data.file} target="_blank">SHOW CREDENTIALS <ArrowForward sx={{ height: "15px", }} /></Link></Typography><br />
                                        </Grid>
                                        {/* {hoveredCertificateItems[index] && (<Grid item xs={2} sm={4} md={4} textAlign={"end"}>
                                            <Box alignSelf={"center"}>
                                                <Button><Edit onClick={() => { openEditCertificateDrawer(data, index) }} /></Button>
                                                <Button onClick={() => openDeleteDialog('userCertificationDetails', data.id, index )} ><Delete /></Button>
                                            </Box>
                                        </Grid>)} */}
                                    </Grid>
                                </ListItemButton>
                            </ListItem>)}

                        </List>
                        <Box textAlign={"center"}>
                            <Button startIcon={<Add />} onClick={openCertificateDrawer}> ADD CERTIFICATE</Button>
                        </Box>
                    </CardContent>
                </Card>
                <SharedDrawer id="education">
                    <AddEducationDrawer parentId='education' currentEditEducationIndex={currentEditEducationIndex} />
                </SharedDrawer>
                <SharedDrawer id="certificate">
                    <AddCertificateDrawer parentId='certificate' drawerHeading={"Add Certificate"} currentEditCertificateIndex={currentEditCertificateIndex} />
                </SharedDrawer>
                <SharedDialog id="deleteDialog">
                    <Box pb="1rem">
                        <DialogTitle id="alert-dialog-title">
                            {"Delete"}
                        </DialogTitle>
                        <DialogContent sx={{ padding: '16px 24px' }}>
                            <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                This will be deleted forever and you won't be able to restore it.
                                {/* {methodsAndState.dynamicDeleteMessage} */}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: '16px 24px' }}>
                            <Button onClick={() => dispatch(closeDialog('deleteDialog'))}>
                                CANCEL
                            </Button>
                            <Button onClick={deleteHandler} color='primary' variant="contained" >YES, DELETE</Button>
                        </DialogActions>
                    </Box>
                </SharedDialog>
            </Box>
        </Box>
    )
}

export default EducationCertificate