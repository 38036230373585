import React, { useEffect } from 'react'
import { Box, Typography, Stack, Grid, Button } from '@mui/material';
import { Add, Delete, Edit, } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';
import SharedDrawer from '../../features/drawers/Drawer';
import AddBankDrawer from '../../components/AdminDrawers/AddBankDrawer';
import { openDrawer } from '../../features/drawers/drawerSlice';

const BankDetail = ({ readOnly }) => {
    const dispatch = useDispatch();
    const openBankDrawer = () => {
        dispatch(openDrawer('bank'));
    }
    const userBankDetails = useSelector((state) => state?.user?.userListName?.userBankDetails ?? []);
    console.log("a1", userBankDetails)
    const setValues = () => {
        if (userBankDetails?.length) {
            bankDetailsHelper.set({
                'Bank Name': userBankDetails[0]["bankName"],
                'Branch Address': userBankDetails[0]["addressOfBranch"],
                'Account No.': userBankDetails[0]['accountNo'],
                'IFSC Code': userBankDetails[0]['ifscCode'],
                'UAN No.': userBankDetails[0]["uanNo"],
                'PF No.': userBankDetails[0]['pfNo'],
            }, () => {
                console.log("Jiren Done")
            })
        }
    }
    useEffect(function () {
        setValues();
        window.scrollTo(0, 0);
    }, [userBankDetails])
    useEffect(function () {
        setValues();
        window.scrollTo(0, 0);
    }, [])
    let inputProps = { readOnly: true };
    let bankDetailModel = [
        { inputProps: inputProps, label: 'Bank Name', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }, { name: 'length', min: 5 }], },
        { inputProps: inputProps, label: 'Branch Address', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], },
        { inputProps: inputProps, label: 'Account No.', type: "textField", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], },
        { inputProps: inputProps, label: 'IFSC Code', type: "textField", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], },
        { inputProps: inputProps, label: 'UAN No.', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], variant: "outlined", },
        { inputProps: inputProps, label: 'PF No.', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], },
    ];
    const bankDetailsHelper = new FormHelper({ model: bankDetailModel, id: 'personalDetails' });
    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Bank Details</Typography>
                    </Box>
                </Stack>
                <Card >
                    <CardContent>
                        {/* <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                                <RenderHelper  {...bankDetailsHelper.model} />
                        </Box> */}
                        {userBankDetails.length == 0 && (
                            <Box>
                                <Box>
                                    <Box textAlign={"center"} onClick={openBankDrawer} >
                                        <Button startIcon={<Add />} > ADD BANK DETAILS</Button>
                                    </Box>
                                </Box>
                                {/* <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    Bank Name
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.primary">
                                                    --
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    Branch Address
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.primary">
                                                    --
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    Account No.
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.primary">
                                                    --
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    IFSC Code
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.primary">
                                                    --
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    UAN No.
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.primary">
                                                    --
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    PF No.
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="text.primary">
                                                    --
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box> */}
                            </Box>

                        )}
                        {userBankDetails.length > 0 && userBankDetails?.map((bank, key) => {
                            // console.log('a1',user?.userListName?.userBankDetails)
                            return (<>
                                <Box>
                                    <Box><Typography variant="subtitle1" color="text.primary">Bank {key + 1}</Typography> </Box>
                                    <Box sx={{my:'1rem'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Bank Name
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {bank.bankName}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Branch Address
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {bank.addressOfBranch}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Account No.
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {bank.accountNo}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        IFSC Code
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {bank.ifscCode}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        UAN No.
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {bank.uanNo}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        PF No.
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {bank.pfNo}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                            )
                        })}
                    </CardContent>
                </Card>
                <SharedDrawer id="bank">
                        <AddBankDrawer parentId='bank' drawerHeading={"Add Bank Details"} />
                </SharedDrawer>
            </Box>
        </Box>
    )
}

export default BankDetail