import React, {useEffect, useState } from 'react'
import { Box, Button, Typography, Stack, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import { Add, Delete, Edit, } from '@mui/icons-material';
import SharedDrawer from '../../features/drawers/Drawer';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AddFamilyDrawer from '../../components/AdminDrawers/AddFamilyDrawer';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { openDialog, closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const FamilyDetail = ({ readOnly }) => {
    const dispatch = useDispatch();
    const userFamilyData = useSelector((state) => state?.user?.userListName?.userFamilyInfo);
    const [hoveredItems, setHoveredItems] = useState(Array(userFamilyData?.length).fill(false));
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [currentEditFamilyIndex, setCurrentEditFamilyIndex] = useState();

    const openFamilyDrawer = () => {
        setCurrentEditFamilyIndex(-1);

        dispatch(openDrawer('family'));
    }
    const openEditFamilyDrawer = (data, index) => {
        dispatch(openDrawer({id: 'family', data}));
        setCurrentEditFamilyIndex(index);
    }
    const handleListItemMouseLeave = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = false;
        setHoveredItems(newHoveredItems);
    };
    const handleListItemMouseEnter = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = true;
        setHoveredItems(newHoveredItems);
    };
    
    const openDeleteDialog = (type, id, index) => {
        setCurerentItemToDelete({type, id, index});
        dispatch(openDialog('deleteDialog'));
    }

    const deleteHandler = () => {
        if(!curerentItemToDelete.type){
            return;
        }
        switch(curerentItemToDelete.type){
            case 'userFamilyData' : console.log("Look here",{userFamilyData, curerentItemToDelete});
                                        dispatch(closeDialog('deleteDialog'));
                                        break;
        }
    }

    useEffect(function () {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Family Details</Typography>
                    </Box>
                </Stack>
                <Card sx={{ mb: 3, p: 3, pb: 0 }}>
                    <CardContent sx={{ p: 0 }}>
                        <List >
                            {userFamilyData?.map((data,index) => <ListItem 
                             onMouseEnter={() => handleListItemMouseEnter(index)}
                             onMouseLeave={() => handleListItemMouseLeave(index)}
                             key={data.id}
                                sx={{ p: 0, mb: 3 }} >
                                <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                    <ListItemText>
                                        <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.type}</Typography>
                                        <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.name}</Typography>
                                    </ListItemText>
                                    {/* {hoveredItems[index] && (
                                        <Box alignSelf={"center"}>
                                            <Button ><Edit  onClick={() => {openEditFamilyDrawer(data, index)}} /></Button>
                                            <Button  onClick={() => openDeleteDialog('userFamilyData', data.id, index )}  ><Delete/></Button>
                                        </Box>)} */}
                                </ListItemButton>
                            </ListItem>)}

                        </List>
                        <Box textAlign={"center"}>
                            <Button startIcon={<Add />} onClick={openFamilyDrawer}> ADD FAMILY DETAILS</Button>
                        </Box>
                    </CardContent>
                </Card>
                <SharedDrawer id="family">
                    <AddFamilyDrawer parentId='family' currentEditFamilyIndex={currentEditFamilyIndex} />
                </SharedDrawer>
                <SharedDialog id="deleteDialog">
                    <Box pb="1rem">
                        <DialogTitle id="alert-dialog-title">
                            {"Delete"}
                        </DialogTitle>
                        <DialogContent sx={{ padding: '16px 24px' }}>
                            <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                This will be deleted forever and you won't be able to restore it.
                                {/* {methodsAndState.dynamicDeleteMessage} */}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: '16px 24px' }}>
                            <Button onClick={() => dispatch(closeDialog('deleteDialog'))}>
                                CANCEL
                            </Button>
                            <Button onClick={deleteHandler} color='primary' variant="contained" >YES, DELETE</Button>
                        </DialogActions>
                    </Box>
                </SharedDialog>
            </Box>
        </Box>
    )
}
export default FamilyDetail