import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

// import '../../assets/styles/Login.css';
import logo from "../../assets/images/logo.png";
import TransitionsSnackbar from '../../features/snakebar/Snackbar';
import { setSnackBar } from '../../features/snakebar/snakebar';
import { useDispatch } from 'react-redux';
import { getCaptchaSetting } from "./getCaptchaSetting";
import { Box, Button, Container, CssBaseline, Dialog, DialogTitle, Grid, InputAdornment, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';
import {registerLinkingHandler} from "auth-component";
import ReCAPTCHA from 'react-google-recaptcha';
import {registerHandler,registerWithCaptchaHandler} from "auth-component";

function Register(props) {

    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [c_password, setC_Password] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [nameErr, setNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [passErr, setPassErr] = useState(false);
    const [c_passErr, setC_PassErr] = useState(false);
    const [dailogMessage, setDailogMessage] = useState('')
    const [open, setOpen] = useState(false);
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // var regexp = " /^[0-9\b]+$/";
    // var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const passwordFormat = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/ ;
    // const passwordFormat = /^(?=[A-Z])[A-Za-z\d@$!%*#?&]{7,}(?=.*\d)(?=.*[a-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]$/ ;
    const [showCaptcha, setShowCaptcha] = useState(true);
    const [captchaSiteKey, setCaptchaSiteKey] = useState("");
    const recaptchaRef = useRef();
    const [reCaptchaValue, setReCaptchaValue] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
 
    const navigate = useNavigate();

    const handleRecaptchaChange = (value) => {
        // Use the reCAPTCHA response value (token) here
        // console.log("ReCAPTCHA response:", value);
        setReCaptchaValue(value);
    };

    useEffect(() => {
        console.log("Hitt")
        getCaptchaSetting().then(response => {
          console.log({response})
          let data = response.data.data;
          if(data){
            setShowCaptcha(data?.[0]?.captcha?.activeStatus == '1');
            setCaptchaSiteKey(data?.[0]?.captcha?.siteKey)
            console.log({siteKey: data?.[0]?.captcha?.siteKey})
          }
        }).catch((e) => {
          console.log({e})
        })
    }, [])

    const handleDialogStatus = () => {
        setOpen(!open);
      };
      

    const submitClickHandler = (e) => {
        e.preventDefault();

        let postData = {};
        if(showCaptcha){
            if(reCaptchaValue){
                setCaptchaError(false);
                postData['g-recaptcha-response'] = reCaptchaValue;
            } else {
                setCaptchaError(true);
                return;
            }
            // postData['captcha'] = true;
          }
        if (mailformat.test(email)){ 
            postData.email = email;
            setEmailErr(false);
        }else{
            setEmailErr(true);
        }
        if (name){ 
            postData.name = name;
            setNameErr(false)
        }else{
           setNameErr(true)
            }
        // if (password.match(regexp) || format.test(password)){ 
        if (passwordFormat.test(password)){ 
            postData.password = password;
            setPassErr(false);
        } else {
          setPassErr(true);
        }
        if (c_password.match(password)){ 
            postData.c_password = c_password;
            setC_PassErr(false);
        } else {
          setC_PassErr(true);
        }
        if (
            mailformat.test(email) && name !="" &&
            c_password.match(password) &&
            // (password.match(regexp) || format.test(password))
            (passwordFormat.test(password))
          ) {
            setDisabled(true);
            registerWithCaptchaHandler(postData).then(r=>{
                setDisabled(false);
                console.debug("Register : submitClickHandler : registerHandler : r : ",r);
                if(r && r.data && r.data.url){
                    // handleDialogStatus()
                    // setDailogMessage(r.detail)
                    window.location.assign(r.data.url)
                }else{
                    dispatch(setSnackBar({ dataLoad: true, message: "Something went wrong", severity: "error" }));
                }
                // if(r !=null){ 
                //     if(r){
                //         handleDialogStatus()
                //         setDailogMessage(r.detail)
                //     }
                //         // window.location.assign(r)
                //         // navigate("/register-view");
                //     // window.location.assign("/")
                //     }else{
                //         dispatch(setSnackBar({ dataLoad: true, message: "Something went wrong", severity: "error" }));
                //     }
                }).catch(e => {
                    setDisabled(false);
                    dispatch(setSnackBar({ dataLoad: true, message: e.message, severity: "error" }));
                })
 
    }
    }

    const formInputChangeHandler = (e) => {
        e.preventDefault();
        if (e.target.name == "name") {
            setName(e.target.value);
          }
        if (e.target.name == "email") {
            if(mailformat.test(e.target.value)){ 
           setEmail(e.target.value);
           setEmailErr(false);
        }else{
            setEmail(e.target.value);
            setEmailErr(true);
        }
        }
        if (e.target.name == "password") {
        //    if(password.match(regexp) || format.test(password)){ 

        console.log("Here", {test: passwordFormat.test(e.target.value)})
            if(passwordFormat.test(e.target.value)){ 
          setPassword(e.target.value);
          setPassErr(false)
           }else{
            setPassword(e.target.value);
            setPassErr(true)
           }
        }
        if (e.target.name == "c_password") {
            console.debug("InputProps:c_password",c_password )
            console.debug("InputProps:password",password )
            if (e.target.value.match(password)){ 
            setC_PassErr(false)
            setC_Password(e.target.value);
            console.debug("InputProps:password:if" )
            }else{
                setC_Password(e.target.value);
                setC_PassErr(true)
            console.debug("InputProps:password:else" )

            }
        }
      };

    return (
        <Grid container component="main" sx={{ height: '100vh', background: '#E9EAED' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    mt: 10,
                    alignItems: 'center',
                }}
            >     <Box className="align-content">

                    <a href="/login"><img width={150} height={130} src={logo} alt="logo" /></a>

                </Box>
                <Box className="align-content">
                    <Box ><Typography variant="h5" component="div" >Every interaction is an opportunity</Typography>   </Box>
                    <Box sx={{
                        mt: 0
                    }}><Typography variant="h5" component="div" >to create <strong>something remarkable.</strong></Typography> </Box>
                    <hr className="custom-register-line" />
                    <Box className="align-content">
                        <i className="">The Tingg Motto</i>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "end" }} style={{ marginRight: '2rem' }}>
                    <Box sx={{ mt: 3, alignItems: 'end' }}> Got an account? <a href="/login" style={{ color: '#007bff' }}>Sign in.</a></Box></Box>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create Your Account
                    </Typography>
                    <Box sx={{ mt: 0 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            onChange={(e) =>  formInputChangeHandler(e)}
                            // onChange={(e) => setName(e.target.value)}
                            autoComplete="name"
                            error={nameErr} 
                            helperText={nameErr ? "Please enter user name":""}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            onChange={(e) =>  formInputChangeHandler(e)}
                            autoComplete="email"
                            error={emailErr} 
                            helperText={emailErr ? "Please enter valid email account":""}
                            autoFocus
                        />
                        
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            // type="password"
                            error={passErr} 
                            helperText={passErr ? "Please enter valid password First letter should be capital and contain special character and number":""}
                            id="password"
                            onChange={(e) =>  formInputChangeHandler(e)}
                            // onChange={(e) => setPassword(e.target.value)}
                            autoComplete="password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button style={{color:'#1BA1CB', fontSize:"700"}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="c_password"
                            label="Confirm Password"
                            type={showCPassword ? "text" : "password"}
                            // type="password"
                            id="c_password"
                            // onChange={(e) => setC_Password(e.target.value)}
                            onChange={(e) =>  formInputChangeHandler(e)}
                            autoComplete="c_password"
                            error={c_passErr} 
                            helperText={c_passErr ? " Please enter valid confirm password":""}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button style={{color:'#1BA1CB', fontSize:"700"}} onClick={() => setShowCPassword(!showCPassword)}>{showCPassword ? 'Hide' : 'Show'}</Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                         {showCaptcha && captchaSiteKey != "" && (<div className="form-group">
                                <ReCAPTCHA
                                      ref={recaptchaRef}
                                      sitekey={captchaSiteKey}
                                      onChange={handleRecaptchaChange}
                                  />
                                  {captchaError && (<Box sx={{fontweight: 400,fontSize: '0.75rem',lineHeight: '1.66',letterSpacing: '0.03333em',textAlign: 'left',margin: '3px 14px 0px 14px',color: '#d32f2f'}}>
                                    Complete the reCAPTCHA verification to proceed.
                                  </Box>)}
                        </div>)}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            disabled={disabled}
                            onClick={submitClickHandler}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ul style={{listStyleType: "circle"}}>
                                <li>Password Length matters; at least 8 characters</li>
                                <li>Add some confusion; include special characters and/or numbers</li>
                                <li>Don't only play small ball; at least one uppercase character</li>
                                <li>Don't go all big either; at least one lowercase character</li>
                            </ul>
    </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }} ><p>By creating an account, I accept Tingg’s
                    <br />

                    <Link style={{ color: '#007bff' }} to="https://tinggit.com/terms-of-service" target="_blank"><u>Terms of Service</u></Link> and <Link style={{ color: '#007bff' }} to="https://tinggit.com/privacy-policies" target="_blank"><u>Privacy Policy</u></Link></p>
                </Box>
                <Dialog onClose={handleDialogStatus} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">{dailogMessage}</DialogTitle>
                </Dialog>
            </Grid>
            <TransitionsSnackbar />
        </Grid>


    )
}

export default Register;