import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Grid, Stack, Dialog, DialogContent, CardHeader, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//Icons
import Avatar from '@mui/material/Avatar';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';
import { AccountCircle, Add, Edit, MoreVert } from '@mui/icons-material';
import ApiService from '../../utils/ApiService';
import SharedDrawer from '../../features/drawers/Drawer';
import AddAllowedIPDrawer from '../../components/AdminDrawers/AddAllowedIPDrawer';
import { useDispatch } from 'react-redux';
import { openDrawer } from '../../features/drawers/drawerSlice';

const IpAddress = ({ readOnly }) => {
    const dispatch = useDispatch();

    let genderOptions = [{ value: 'Male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'preferNotToSay', label: 'Prefer not to say' }]
    const [userData, setUserdata] = useState("")
    const [edit, setEdit] = useState(false);
    // const [hardRefresh, setHardRefresh] = useState(true);

    // useEffect(() => {
    //     if(!hardRefresh){
    //         setHardRefresh(true);
    //     }
    // }, [hardRefresh])




    let inputProps = readOnly ? { readOnly: readOnly } : {};

    let allowedIpDetailsModel = [
        { inputProps: inputProps, label: 'IP Name', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[A-Za-z]+$/, errorMessage: 'Field must only contain alphabets' }] }];

    const allowedIPDetailsHelper = new FormHelper({ model: allowedIpDetailsModel, id: 'personalDetails' });
    const openAllowedIPDrawer = () => {
        dispatch(openDrawer('allowedIp'));
    }
    return (
        <Box>
            <Box >
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Allowed IP Address</Typography>
                    </Box>
                </Stack>
                <Card >
                    <CardContent>
                        <Box textAlign={"center"}>
                            <Button startIcon={<Add />} onClick={openAllowedIPDrawer}> ADD ALLOWED IP ADDRESS</Button>
                        </Box>
                    </CardContent>
                </Card>
                <SharedDrawer id="allowedIp">
                    <AddAllowedIPDrawer parentId='allowedIp' drawerHeading={"Add Mobile Number"} candidateDetailsModel={allowedIPDetailsHelper} />
                </SharedDrawer>
            </Box>
        </Box>
    )
}
export default IpAddress