import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Stack, Badge, CardHeader, Grid, FormControlLabel, Checkbox } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';
import { Edit, Save } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ApiService from '../../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackBar } from '../../features/snakebar/snakebar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { LoadingButton } from '@mui/lab';
import validationHelper from '../../form/helper/validationHelper';
import { setUserInfo } from '../../features/userInfoStore/userSlice';
import { openDialog } from '../../features/dialogBox/dialogBoxSlice';
import SharedDialog from '../../features/dialogBox/dialogBox';
import ChangeImageProfile from '../../components/AdminDialog/ChangeImageProfile';

const PersonalDetail = ({ readOnly }) => {

    const dispatch = useDispatch();

    let genderOptions = [{ value: 'Male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'preferNotToSay', label: 'Prefer not to say' }];
    let bloodGroupOptions = [{ value: 'A+', label: 'A+' }, { value: 'A-', label: 'A-' }, { value: 'B+', label: 'B+' }, { value: 'B-', label: 'B-' }, { value: 'AB+', label: 'AB+' }, { value: 'AB-', label: 'AB-' }, { value: 'O+', label: 'O+' }, { value: 'O-', label: 'O-' },];
    const [edit, setEdit] = useState(false);
    const userData = useSelector((state) => state?.user?.userListName);
    // const [cities, setCities] = useState([]);
    // const [states, setStates] = useState([]);
    // const [countries, setCountries] = useState([]);
    const [userApi, setUserApi] = useState(false);
    const [userPermanentAddressApi, setUserPermanentAddressApi] = useState(false);
    const [userPresentAddressApi, setUserPresentAddressApi] = useState(false);
    const [disableSumbit, setDisableSumbit] = useState(false);
    const [currentAddressId, setCurrentAddressId] = useState();
    const [loading, setLoading] = React.useState(false);
    const [showErrors, setShowErrors] = React.useState(0);

    let inputProps = readOnly ? { readOnly: readOnly } : {};
    let personalDetailsModel = [
        { inputProps: inputProps, label: 'First Name', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }, { name: 'length', min: 2 }] },
        { inputProps: inputProps, label: 'Last Name', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }] },
        { inputProps: inputProps, label: 'Gender', type: "singleSelect", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], data: genderOptions },
        { inputProps: inputProps, label: 'Date Of Birth', type: "dateField", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }] },
        // { inputProps: inputProps, label: 'Email', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/, errorMessage: 'e.g: hello@gmail.com' }], variant: "outlined", },
        { inputProps: inputProps, label: 'Blood Group', type: "singleSelect", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], data: bloodGroupOptions },
        { inputProps: inputProps, label: 'Phone Number', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[0-9]+$/, errorMessage: 'Field must only contain number' }, { name: 'length', min: 10, errorMessage: 'Minimum 10 digits required' }, { name: 'length', max: 12, errorMessage: 'Maximum 12 digits permitted' }] },
        // { inputProps: inputProps, label: 'Interest', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [] },
        // { inputProps: inputProps, label: 'Linkedin Profile', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }, { name: 'length', max: 15 }] },
    ];

    let userPresentAddress = [
        { label: 'Address', type: "textField", value: '', sx: { gridColumn: 'span 4', m: 1 }, validators: [{ name: 'required' }] },
        { inputProps: inputProps, label: 'City', type: "singleSelect", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], data: [] },
        { inputProps: inputProps, label: 'State', type: "singleSelect", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], data: [] },
        { inputProps: inputProps, label: 'Country', type: "singleSelect", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], data: [] },
        { label: 'Pincode', type: "textField", value: '', sx: { gridColumn: 'span 2', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[0-9]+$/, errorMessage: 'Field must only contain numbers' }] },
    ];

    let userPermanentAddress = [
        { label: 'Address', type: "textField", value: '', sx: { gridColumn: 'span 4', m: 1 }, validators: [{ name: 'required' }] },
        { inputProps: inputProps, label: 'City', type: "singleSelect", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], data: [] },
        { inputProps: inputProps, label: 'State', type: "singleSelect", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], data: [] },
        { inputProps: inputProps, label: 'Country', type: "singleSelect", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], data: [] },
        { label: 'Pincode', type: "textField", value: '', sx: { gridColumn: 'span 2', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[0-9]+$/, errorMessage: 'Field must only contain numbers' }] },
    ];

    const [personalDetailsHelperData, setPersonalDetailsHelperData] = useState(personalDetailsModel);
    const [userPresentAddressHelperData, setUserPresentAddressHelperData] = useState(userPresentAddress);
    const [userPermanentAddressHelperData, setUserPermanentAddressHelperData] = useState(userPermanentAddress);

    let personalDetailsHelper = new FormHelper({ model: personalDetailsModel, id: 'personalDetails' });
    let userPresentAddressHelper = new FormHelper({ model: userPresentAddress, id: 'presentAddress' });
    let userPermanentAddressHelper = new FormHelper({ model: userPermanentAddress, id: 'permananetAddress' });

    const setValues = () => {
        console.log("Jiren", { userData })
        if (userData.userName) {
            personalDetailsHelper.set({
                'First Name': userData["userName"],
                'Last Name': userData["lastName"],
                'Gender': userData['gender'],
                'Date Of Birth': userData['dateOfBirth'],
                'Email': userData["email"],
                'Blood Group': userData['bloodGroup'],
                'Phone Number': userData['contactNo'],
            }, () => {
                // personalDetailsHelper.model = updatedModel;
                console.log("Jiren Done", { form: personalDetailsHelper.get(), model: personalDetailsHelper.model })
            })
            if (userData?.userAddress?.[0]) {
                setCurrentAddressId(userData.userAddress[0]["id"])

                userPermanentAddressHelper.set({
                    'Address': userData.userAddress[0]["address"],
                    'City': userData.userAddress[0]["city"],
                    'State': userData.userAddress[0]['state'],
                    'Country': userData.userAddress[0]['country'],
                    'Pincode': userData.userAddress[0]["pincode"],
                }, () => {
                    // userPermanentAddressHelper.model = updatedModel;
                })
            }
            if (userData?.userAddress?.[1]) {
                userPresentAddressHelper.set({
                    'Address': userData.userAddress[1]["address"],
                    'City': userData.userAddress[1]["city"],
                    'State': userData.userAddress[1]['state'],
                    'Country': userData.userAddress[1]['country'],
                    'Pincode': userData.userAddress[1]["pincode"],
                }, () => {
                    // userPresentAddressHelper.model = updatedModel;
                })
            }
        }
    }

    useEffect(function () {
        setValues();
        window.scrollTo(0, 0);
    }, [userData])

    const handleCropSave = () => {
        dispatch(openDialog('changeProfileDialog'));
    }

    function onSubmitHandler() {
        setUserApi(true);
        setUserPermanentAddressApi(true);
        setUserPresentAddressApi(true);

        let dataPersonalDetailsHelper = personalDetailsHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);

        var error = false;
        var errorMessage = false;

        for (let i = 0; i < personalDetailsHelperData.length; i++) {
            for (let j = 0; j < personalDetailsHelperData[i]?.validation?.length; j++) {
                // console.log("value test", {value: personalDetailsHelperData[i]?.validation[j].value, data: personalDetailsHelperData[i]})
                var res = personalDetailsHelperData[i]?.validation[j](personalDetailsHelperData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    // console.log("So", {field: personalDetailsHelperData[i], j, res})
                    break;
                }
            }
        }
        // console.log("Api Call 1", {error, dataPersonalDetailsHelper,errorMessage})
        if (error) {
            setShowErrors(showErrors + 1);
            return;
        }

        let formData = new FormData();
        // if(dataPersonalDetailsHelper['First Name'] && dataPersonalDetailsHelper['Last Name'] && dataPersonalDetailsHelper['Date Of Birth'] && dataPersonalDetailsHelper['Phone Number']){

        console.log("Api Call")

        formData.append('name', dataPersonalDetailsHelper['First Name']);
        formData.append('lastName', dataPersonalDetailsHelper['Last Name']);
        formData.append('gender', dataPersonalDetailsHelper['Gender']);
        formData.append('birthday', dataPersonalDetailsHelper['Date Of Birth']);
        // formData.append('email', dataPersonalDetailsHelper['Email']);
        formData.append('bloodGroup', dataPersonalDetailsHelper['Blood Group']);
        formData.append('mobileNo', dataPersonalDetailsHelper['Phone Number']);
        // formData.append('linkedin', dataPersonalDetailsHelper['Linkedin Profile']);
        // formData.append('interest', dataPersonalDetailsHelper['Interest']);
        // formData.append('userId', userData.userId);
        setLoading(true)
        ApiService.postAdopter('updateUser', formData, userData.userId).then((res) => {
            console.log({ res })
            if (res) {
                setLoading(false)
                setEdit(false)
                ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
                    let response = res.data.data
                    if (response) {
                        dispatch(setUserInfo(response));
                    }
                }).catch((error) => {
                    console.log(error);
                    dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
                })
            }
            setUserApi(false);
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            setUserApi(false);
            setLoading(false)
            setUserPermanentAddressApi(false);
            setUserPresentAddressApi(false);
            setEdit(false)

        })

    }



    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Personal Details</Typography>
                    </Box>
                </Stack>
                <Card >
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center',padding:'0.5rem' }}>
                        <Box sx={{display:'flex'}}>
                        <CardHeader
                        avatar={
                            <Box sx={{ m: 1 ,mr:3}}>
                            <Badge
                                sx={{ borderRadius: '100px' }}
                                id="edit-icon"
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <Avatar sx={{ height: '1.5rem', width: '1.5rem',border: '1px solid white' }} onClick={handleCropSave}>
                                        <ModeEditIcon sx={{ fontSize: 'small' }} />
                                    </Avatar>
                                }
                            >
                                <Avatar id="profile-icon" alt="Travis Howard" src={userData?.userAvatar} sx={{ height: 65, width: 65 }} />
                            </Badge>
                        </Box>
                        }
                        title={<Typography variant='h6'>{userData?.userName} {userData?.lastName}</Typography>}
                        subheader={<Typography variant='body2'>{userData?.email}</Typography>}
                    />
                            {/* <Box sx={{ m: 3 }}>
                                <Badge
                                    // onClick={handleClickOpen}
                                    sx={{ borderRadius: '100px' }}
                                    id="edit-icon"
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <Avatar sx={{ height: '1.5rem', width: '1.5rem', marginRight: 'var(--margin-right-1)', border: '1px solid white' }} onClick={handleCropSave}>
                                            <ModeEditIcon sx={{ fontSize: 'small' }} />
                                        </Avatar>
                                    }
                                >
                                    <Avatar id="profile-icon" alt="Travis Howard" src={userData?.userAvatar} sx={{ height: 65, width: 65 }} />
                                </Badge>
                            </Box> */}
                            {/* <Box>
                            title={<Typography variant='h7'>{userData?.userName} {userData?.lastName}</Typography>}
                        subheader={<Typography variant='body2'>{userData?.email}</Typography>}
                            </Box> */}
                        </Box>
                        <Box>
                            <Box>
                                {!edit ? <Button variant="contained" sx={{ mx: 1 }} onClick={() => setEdit(true)} startIcon={<Edit />}><Typography variant="body2">EDIT</Typography></Button>
                                    : <> <Button variant="outlined" sx={{ mx: 1 }} onClick={() => setEdit(false)} ><Typography variant="body2">CANCEL</Typography></Button>
                                        <LoadingButton
                                            color="primary"

                                            loading={loading}
                                            loadingPosition="start"
                                            startIcon={<Save />}
                                            variant="contained"
                                            disabled={disableSumbit}
                                            onClick={onSubmitHandler}
                                        >
                                            <span>SAVE</span>
                                        </LoadingButton>
                                    </>}
                            </Box>
                        </Box>
                    </Box>

                    <CardContent>
                        {/* <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                            <RenderHelper editMode={edit} showErrors={showErrors}  {...personalDetailsHelper.model} getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setPersonalDetailsHelperData(data) }} />
                        </Box>  */}
                        {/* {edit ? */}
                        <Box component="form" sx={{ display: edit ? 'grid' : 'none', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                            <RenderHelper showErrors={showErrors}  {...personalDetailsHelper.model} getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setPersonalDetailsHelperData(data) }} />
                        </Box>
                        {/* : */}
                        <Box sx={{ opacity: edit ? 0 : 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            First Name
                                        </Typography>
                                    </Box>
                                    <Box> 
                                    <Typography variant="subtitle2" color="text.primary">
                                    {userData.userName ?? '--'}
                                    </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Last Name
                                        </Typography>
                                    </Box>
                                    <Box> 
                                    <Typography variant="subtitle2" color="text.primary">
                                    {userData.lastName ?? '--'}
                                    </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Gender
                                        </Typography>
                                    </Box>
                                    <Box> 
                                    <Typography variant="subtitle2" color="text.primary">
                                    {userData.gender ?? '--'}
                                    </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Date of Birth
                                        </Typography>
                                    </Box>
                                    <Box> 
                                    <Typography variant="subtitle2" color="text.primary">
                                    {userData.dateOfBirth ?? '--'}
                                    </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Blood Group
                                        </Typography>
                                    </Box>
                                    <Box> 
                                    <Typography variant="subtitle2" color="text.primary">
                                    {userData.bloodGroup ?? '--'}
                                    </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Phone Number
                                        </Typography>
                                    </Box>
                                    <Box> 
                                    <Typography variant="subtitle2" color="text.primary">
                                    {userData.contactNo ?? '--'}
                                    </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* // } */}
                    </CardContent>
                </Card>
            </Box>
            <SharedDialog id='changeProfileDialog'>
                <Box >
                    <ChangeImageProfile />
                </Box>
            </SharedDialog>
        </Box>
    )
}

export default PersonalDetail