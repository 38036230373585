import React, { useEffect, useState } from "react";
import './emailResetPassword.css'
import {  useLocation, useParams } from "react-router-dom";
import {ResetForgotPasswordHandler} from 'auth-component';
import TransitionsSnackbar from "../../features/snakebar/Snackbar";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { useDispatch } from 'react-redux';
import logo from "../../assets/images/logo.png";


function EmailResetPassword(props) {
    // let history = useHistory();
    let { token } = useParams();
    const search = useLocation().search;
    let email = new URLSearchParams(search).get('email');
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [passErr, setPassErr] = useState("");
    const [c_passErr, setC_PassErr] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [disabled, setDisabled] = useState(false);
	const dispatch = useDispatch();

    const formInputChangeHandler = (e) => {
        e.preventDefault();
        if (e.target.name == 'password_confirmation') {
            setConfirmPassword(e.target.value)
        }
        if (e.target.name == 'password') {
            setPassword(e.target.value)
        }

    }

    const submitClickHandler = (e) => {
        e.preventDefault();


        let postData = {};
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // var regexp = " /^[0-9\b]+$/"
        // var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const passwordFormat = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/ ;
        postData.token = token;
        if (mailformat.test(email)) {
            postData.email = email;
            setEmailErr("")
        } else {
            setEmailErr(<div className="form-group">
                <span className="text-danger">Please enter valid email</span>
            </div>)
        }

        // if(password)
        //     postData.password=password;
        if (passwordFormat.test(password)) {
        // if (password.match(regexp) || format.test(password)) {
            postData.password = password;
            setPassErr("")
        } else {
            setPassErr(<div className="form-group">
                <span className="text-danger">Please enter valid password (First letter should be capital and contain special character and number )</span>
            </div>)
        }
        if (confirmPassword.match(password)) {
            postData.c_password = confirmPassword;
            setC_PassErr("")
        } else {

            setC_PassErr(<div className="form-group">
                <span className="text-danger">Please enter valid confirm password</span>
            </div>)
        }

        // if (mailformat.test(email) && confirmPassword.match(password) && (password.match(regexp) || format.test(password))) {
        if (mailformat.test(email) && confirmPassword.match(password) && (passwordFormat.test(password))) {
            // props.setOpenBackdrop(true);
            setDisabled(true);
            ResetForgotPasswordHandler(postData).then(r=>{
                setDisabled(false);
                console.debug("submitClickHandler : resetForgotPasswordHandler : r : ",r);
                if(r.redirectUrl){
                    dispatch(setSnackBar({ dataLoad: true, message: "Password Reset Successfully.", severity: "success" }))
                    // history.push(r.redirectUrl);
                    // Password rest succesfully now redirect login page
                    window.location.assign(r.redirectUrl) 
                }else{
                    dispatch(setSnackBar({ dataLoad: true, message: "Something went wrong", severity: "error" }))
                    console.error("Something went Wrong");
                }
            }).catch(e=>{
                // So Dialog for Token is expried or Or token is already used 
                setDisabled(false);
                dispatch(setSnackBar({ dataLoad: true, message: e.message, severity: "error" }))
                console.error("submitClickHandler : resetForgotPasswordHandler : e : ",e.response);
            });


            // let ab = resetForgetPassword(postData).then(function (r) {
            //     console.debug(r);
            //     if (r.data && r.data.data) {
            //         console.log("success reponse")
            //         // props.setOpenBackdrop(false);
            //         // Password rest succesfully now redirect login page
            //         history.push("/login");
            //     } else {
            //         // props.setOpenBackdrop(false);
            //         console.log("error reponse")
            //     }
            // }).catch(e=>{
            //     // So Dialog for Token is expried or Or token is already used
            // })
        }
        else {
            console.debug("error")
        }

    }
    return (
        <>
            <div className="section-one">
                <div className="inner-box">
                    <div className="content-container">
                        <div className="logo">
                            <a href="">
                                <img src={logo} alt="logo" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="inner-box-heading">
                    <div className="content-container">
                        <h2 className="heading-style">Every interaction is an opportunity</h2>
                    </div>
                    <div className="content-container">
                        <h2 className="heading-style">to create <strong>something remarkable.</strong></h2>
                    </div>
                    <div className="content-container">
                        {/* <hr className="custom-register-line">    */}
                    </div>
                    <div className="content-container">
                        <i>The Tingg Motto</i>
                    </div>
                </div>
            </div>

            <div className="section-two">
                <div className="registered-view-container">
                    <div className="card">
                        <div className="d-flex align-items-end flex-column bd-highlight">
                            <h2 className="card-title">Change Your Password</h2>
                            <span className="card-title-desc"></span>
                            <div className="resetformcontainer marginauto">
                                <input type="hidden" name="token" value="{{ $token }}" />
                                <div className="form-group">
                                    <label className="form-label">Email*</label>
                                    <input id="email" type="email" className="form-control" name="email" value={email} disabled autofocus />

                                    <span className="invalid-feedback" role="alert">
                                    </span>
                                </div>
                                {emailErr}
                                <div className="form-group">
                                    <label className="form-label">Password*</label>
                                    <a href="#" id="show-password-ref" className="showPassword" onClick={(e) => setShowPassword(!showPassword)}>Show Password</a>
                                    <input id="password" type={showPassword ? "text" : "password"} className="form-control" name="password" required onChange={formInputChangeHandler} />

                                    <span className="invalid-feedback" role="alert">
                                    </span>
                                </div>
                                {passErr}
                                <div className="form-group">
                                    <label className="form-label">Confirm Password*</label>
                                    <a href="#" id="show-password-ref" className="showCPassword" onClick={(e) => setShowCPassword(!showCPassword)}>Show Password</a>
                                    <input id="password-confirm" type={showCPassword ? "text" : "password"} className="form-control" name="password_confirmation" required onChange={formInputChangeHandler} />
                                </div>
                                {c_passErr}
                                <div className="form-group submitwrp">
                                    <div>
                                        <input type="submit" name="submit" className="btn btn-custom orangeBtn" value="Change Password" onClick={(e) => submitClickHandler(e)} />
                                    </div>
                                </div>
                                <div className="password-set-hints" style={{ textAlign: "left", padding: "20px 0px" }}>
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>Password Length matters; at least 8 characters</li>
                                        <li>Add some confusion; include special characters and/or numbers</li>
                                        <li>Don't only play small ball; at least one uppercase character</li>
                                        <li>Don't go all big either; at least one lowercase character</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TransitionsSnackbar />
        </>
    )
}

export default EmailResetPassword;