import React, { useEffect, useState } from "react";
// import {forgetPassword} from '../../_services/user'
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, Container, CssBaseline, Dialog, DialogTitle, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { forgotPasswordHandler } from "auth-component"
import TransitionsSnackbar from '../../features/snakebar/Snackbar';
import { setSnackBar } from '../../features/snakebar/snakebar';
import { useDispatch } from 'react-redux';
import logo from "../../assets/images/logo.png";


function ForgotPassword(props) {

    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [open, setOpen] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [dailogMessage, setDailogMessage] = useState('')
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();

    const submitClickHandler = (e) => {
        e.preventDefault();
        // console.log(window.location.origin);
        let postData = {};
        // handleDialogStatus()

        if (mailformat.test(email)) {
            postData.email = email;
        } else {
            setEmailErr(true)
        }
        if (mailformat.test(email)) {
            postData.src = window.location.origin;
            setDisabled(true);
            forgotPasswordHandler(postData).then(response => {
                setDisabled(false);
                console.debug('ForgetPassword : submitClickHandler : forgotPasswordHandler : response : ',response);
                if(response.title){
                    // setDailogMessage('Mail has been sent to your registered email')
                    setDailogMessage(response.title);
                }else{
                    setDailogMessage("Something Went Wrong");
                }
                handleDialogStatus()

                // if (response.status != 200) {
                //     // dispatch(setSnackBar({ dataLoad: true, message: response.detail, severity: "success" }));
                //     setDailogMessage(response.detail)
                //     // setTimeout(function () {
                //     //     navigate("//login");
                //     // }, 5000)
                // } else {
                //     setDailogMessage('Mail has been sent to your registered email')
                //     // dispatch(setSnackBar({ dataLoad: true, message: 'Mail has been sent to your registered email', severity: "success" }));
                // }
                // handleDialogStatus()

            }).catch(e => {
                setDisabled(false);
                dispatch(setSnackBar({ dataLoad: true, message: e.message, severity: "error" }));
            });
        }
    }
    const formInputChangeHandler = (e) => {
        e.preventDefault();
        if (e.target.name == 'email') {
            if (mailformat.test(e.target.value)) {
                setEmail(e.target.value)
                setEmailErr(false);
            } else {
                setEmail(e.target.value)
                setEmailErr(true);
            }
        }
    }

    const handleDialogStatus = () => {
        setOpen(!open);
    };




    return (
        <Grid container component="main" sx={{ height: '100vh', background: '#E9EAED' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    mt: 10,
                    alignItems: 'center',
                }}
            >     <Box className="align-content">

                    <a href="/login"><img width={150} height={130} src={logo} alt="logo" /></a>

                </Box>
                <Box className="align-content">
                    <Box ><Typography variant="h5" component="div" >Every interaction is an opportunity</Typography>   </Box>
                    <Box sx={{
                        mt: 0
                    }}><Typography variant="h5" component="div" >to create <strong>something remarkable.</strong></Typography> </Box>
                    <hr className="custom-register-line" />
                    <Box className="align-content">
                        <i className="">The Tingg Motto</i>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "end" }} style={{ marginRight: '2rem' }}>
                    <Box sx={{ mt: 3, alignItems: 'end' }}> Got an account? <a href="/login" style={{ color: '#007bff' }}>Sign in.</a></Box></Box>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Nothing to worry about. We have you covered
                    </Typography>
                    <Typography component="p" variant="p">All you need to do input your registered email and leave the rest to us.</Typography>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            error={emailErr}
                            helperText={emailErr ? "Please enter a valid email account" : ""}
                            // onChange={(e) => setEmail(e.target.value)}
                            onChange={(e) => formInputChangeHandler(e)}
                            autoComplete="email"
                            autoFocus
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            disabled={disabled}
                            onClick={submitClickHandler}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }} ><p>By creating an account, I accept Tingg’s
                    <br />

                    <Link style={{ color: '#007bff' }} to="https://tinggit.com/terms-of-service" target="_blank"><u>Terms of Service</u></Link> and <Link style={{ color: '#007bff' }} to="https://tinggit.com/privacy-policies" target="_blank"><u>Privacy Policy</u></Link></p>
                </Box>
                <Dialog onClose={handleDialogStatus} aria-labelledby="simple-dialog-title" open={open}>
                    <DialogTitle id="simple-dialog-title">{dailogMessage}</DialogTitle>
                </Dialog>
            </Grid>
            <TransitionsSnackbar />
        </Grid>



    )
}

export default ForgotPassword;