import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import { Box, Grid, Typography, Button, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import ApiService from "../../utils/ApiService";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { setUserInfo } from "../../features/userInfoStore/userSlice";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

let tempBI = BasicInformationData

localStorage.setItem("tempBI", JSON.stringify(tempBI))

//Tab Panels Code
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const AddCertificateDrawer = ({ id, parentId, drawerHeading, currentEditCertificateIndex }) => {
    const userData = useSelector((state) => state?.user?.userListName);
    const userCertificateData = useSelector((state) => state?.drawer?.data); // {} by default
    const [showErrors, setShowErrors] = useState(0);
    const [loading,setLoading]=useState(false);

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        dispatch(closeDrawer(parentId));
    };

    let certificateDetailsModel = [
        { label: 'Certification Name', type: "textField", value: '', sx: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' },{ name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }] },
        // { label: 'Issue by Organization', type: "textField", size: 'middle', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }] },
        { label: 'Description', type: "textField", size: 'middle', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' },{ name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }] },
        { label: 'Upload Document', type: "uploadFile", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }], other: {fileExtension: ["image/png", "image/jpeg", "image/jpg", "application/pdf",".pdf",'.jpg','.jpeg','.png'], fileAllowedMessage: "PNG, JPG, PDF and JPEG"} },

        // { label: 'Credential URL', type: "textField", value: '', sx: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }] },
        // { label: 'Issue Month', views: ['month'], type: "dateField", value: '', sx: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }] },
        // { label: 'Issue Year', views: ['year'], type: "dateField", value: '', sx: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }] },
        // { label: 'Expiration Month', views: ['month'], type: "dateField", value: '', sx: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }] },
        // { label: 'Expiration Year', views: ['year'], type: "dateField", value: '', sx: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }] },
    ];

    const certificateDetailsHelper = new FormHelper({ model: certificateDetailsModel, id: 'candidateDetails' });

    const [candidateDetailsHelperData, setCandidateDetailsHelperData] = useState(certificateDetailsModel);


    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }
    const addContactDetail = () => {
        let dataCandidateDetalsHelper = candidateDetailsHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);

        // console.log({dataCandidateDetalsHelper})
        // return;
        var error = false;
        var errorMessage = false;

        for (let i = 0; i < candidateDetailsHelperData.length; i++) {
            for (let j = 0; j < candidateDetailsHelperData[i]?.validation?.length; j++) {
                var res = candidateDetailsHelperData[i]?.validation[j](candidateDetailsHelperData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    break;
                }
            }
        }
        if (error) {
            setShowErrors(showErrors + 1);
            return;
        }

        if (currentEditCertificateIndex == -1) {
            console.log({ dataCandidateDetalsHelper })
            setLoading(true)
            // console.log({dataCandidateDetalsHelper, month: dataCandidateDetalsHelper['Issue Month']})
            // dataCandidateDetalsHelper['Issue Month'] = dataCandidateDetalsHelper['Issue Month']['$M'];
            // dataCandidateDetalsHelper['Issue Year'] = dataCandidateDetalsHelper['Issue Year']['$y'];
            // dataCandidateDetalsHelper['Expiration Month'] = dataCandidateDetalsHelper['Expiration Month']['$M'];
            // dataCandidateDetalsHelper['Expiration Year'] = dataCandidateDetalsHelper['Expiration Year']['$y'];
            let formData = new FormData();
            formData.append('title', dataCandidateDetalsHelper['Certification Name']);
            formData.append('description', dataCandidateDetalsHelper['Description']);
            formData.append('file', dataCandidateDetalsHelper['Upload Document'][0]);
            formData.append('userId', userData.userId);
            // formData.append('userId', userData.userId);
            ApiService.postAdopter('userCertificateUpdate', formData).then((res) => {
                console.log({ res })
                if (res) {
                    handleDrawerClose()
                    getUserData()
                    if (res.status == 200) {
                        setLoading(false)
                        dispatch(setSnackBar({ dataLoad: true, message: "Data added successfully", severity: "success" }))
                    }
                }
            }).catch((error) => {
                console.log(error);

                setLoading(false)
                dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            })
        } else {
            let formData = new FormData();
            formData.append('title', dataCandidateDetalsHelper['Certification Name']);
            formData.append('description', dataCandidateDetalsHelper['Description']);
            formData.append('file', dataCandidateDetalsHelper['Upload Document'][0]);
            formData.append('userId', userData.userId);
            // formData.append('userId', userData.userId);
            setLoading(true)
            ApiService.postAdopter('userCertificateUpdate', formData, userCertificateData.id).then((res) => {
                console.log({ res })
                if (res) {
                    handleDrawerClose()
                    getUserData()
                    if (res.status == 200) {
                        setLoading(false)
                        dispatch(setSnackBar({ dataLoad: true, message: "Data edited successfully", severity: "success" }))
                    }
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false)
                dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            })
        }
    }
    useEffect(() => {
        if (userCertificateData) {
            certificateDetailsHelper.set({
                'Certification Name': userCertificateData.title,
                'Description': userCertificateData.description,
                'Upload Document': userCertificateData.file
            }, () => {
                // personalDetailsHelper.model = updatedModel;
            })

        }
    }, [userCertificateData])
    return (<>
        <Stack sx={{ backgroundColor: "var(--color-lightblue)", mb: 'var(--equal-hw1)', }}>
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Box pl='1rem' display='flex'>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >{currentEditCertificateIndex == -1 ? "Add Certificate" : "Edit Certificate"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                            <CloseIcon sx={{ color: 'var(--color-black)' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
        <Box sx={{ height: "90vh", overflowY: "auto" }}>
            <Box sx={{ width: 1 }}>
                <Box component="form" p={2} sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setCandidateDetailsHelperData(data) }} {...certificateDetailsHelper.model} />
                </Box>
                <Box display={"flex"} sx={{ p: 2 }}>
                    <LoadingButton
                        color="primary"

                        loading={loading}
                        loadingPosition="start"
                        startIcon={<Save />}
                        variant="contained"
                        // disabled={disableSumbit}
                        onClick={addContactDetail}
                    >
                        <span>{currentEditCertificateIndex == -1 ? "ADD" : "EDIT"}</span>
                    </LoadingButton>
                    {/* <Button variant="contained" sx={{ mx: 1 }} onClick={addContactDetail} ><Typography variant="body2">{currentEditCertificateIndex == -1 ? "ADD" : "EDIT"}</Typography></Button> */}
                    <Button variant="outlined" sx={{ mx: 1 }} onClick={handleDrawerClose}><Typography variant="body2">CANCEL</Typography></Button>
                </Box>
            </Box>
        </Box>
    </>)
}

export default AddCertificateDrawer;