import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Stack, CardHeader, List, ListItem, ListItemButton, ListItemText, Skeleton } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import { Add, Delete, Edit, } from '@mui/icons-material';
import SharedDrawer from '../../features/drawers/Drawer';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AddContactDrawer from '../../components/AdminDrawers/AddContactDrawer';
import AddEmailDrawer from '../../components/AdminDrawers/AddEmailDrawer';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { openDialog, closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ApiService from '../../utils/ApiService';
import { setSnackBar } from '../../features/snakebar/snakebar';
import { setUserInfo } from '../../features/userInfoStore/userSlice';
import AddAddressDrawer from '../../components/AdminDrawers/AddAddressDrawer';

const ContactDetail = ({ }) => {
    const dispatch = useDispatch();
    const userAddressDetails = useSelector((state) => state?.user?.userListName?.userAddress);
    const userContactDetails = useSelector((state) => state?.user?.userListName?.userContact);
    const userEmailDetails = useSelector((state) => state?.user?.userListName?.userEmail);
    const [hoveredItems, setHoveredItems] = useState(Array(userContactDetails?.length).fill(false));
    const [hoveredEmailItems, setHoveredEmailItems] = useState(Array(userEmailDetails?.length).fill(false));
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [currentEditEmailIndex, setCurrentEditEmailIndex] = useState();
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const openAddressDrawer = () => {
        dispatch(openDrawer('address'));
    }
    const openMobileDrawer = () => {
        dispatch(openDrawer('mobile'));
    }
    const openEmailDrawer = () => {
        setCurrentEditEmailIndex(-1);
        dispatch(openDrawer('email'));
    }
    const openEditEmailDrawer = (data, index) => {
        dispatch(openDrawer({ id: 'email', data }));
        setCurrentEditEmailIndex(index);
    }
    const handleListItemMouseLeave = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = false;
        setHoveredItems(newHoveredItems);
    };
    const handleListItemMouseEnter = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = true;
        setHoveredItems(newHoveredItems);
    };
    const handleEmailListItemMouseLeave = (index) => {
        const newHoveredEmailItems = [...hoveredEmailItems];
        newHoveredEmailItems[index] = false;
        setHoveredEmailItems(newHoveredEmailItems);
    };
    const handleEmailListItemMouseEnter = (index) => {
        const newHoveredEmailItems = [...hoveredEmailItems];
        newHoveredEmailItems[index] = true;
        setHoveredEmailItems(newHoveredEmailItems);
    };

    const openDeleteDialog = (type, id, index) => {
        setCurerentItemToDelete({ type, id, index });
        dispatch(openDialog('deleteDialog'));
    }
    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
        
    }
    const deleteHandler = () => {
        if (!curerentItemToDelete.type) {
            return;
        }
        switch (curerentItemToDelete.type) {
            case 'userContactDetails':
                ApiService.delete('userContact', {}, curerentItemToDelete.id).then((res) => {
                    console.log({ res })
                }).catch((error) => {
                    console.log(error);
                    dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
                })
                dispatch(closeDialog('deleteDialog'));
                break;
            case 'userEmailDetails':
                ApiService.delete('userEmail', {}, curerentItemToDelete.id).then((res) => {
                    console.log({ res })
                    getUserData()
                }).catch((error) => {
                    console.log(error);
                    dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
                })
                dispatch(closeDialog('deleteDialog'));
                break;
        }
    }
    useEffect(function () {
        // setValues();
        ApiService.get('city').then((res) => {
            let data = res.data.data;
            if (data) {
                setCities(data.map(v => {
                    return {
                        // value: v.cityId,
                        value: v.city,
                        label: v.city
                    }
                }))
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            
        })

        ApiService.get('state').then((res) => {
            let data = res.data.data;
            if (data) {
                console.debug("stateData", data)
                setStates(data.map(v => {
                    return {
                        // value: v.stateId,
                        value: v.state,
                        label: v.state != null ? v.state : ""
                    }
                }))
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

        ApiService.get('country').then((res) => {
            let data = res.data.data;
            if (data) {
                setCountries(data.map(v => {
                    return {
                        // value: v.countryId,
                        value: v.country,
                        label: v.country
                    }
                }))
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
        window.scrollTo(0, 0);

    }, [])
    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Contact Details</Typography>
                    </Box>
                </Stack>
                <Box>
                    <Card sx={{ mb: 3, p: 3, pb: 0 }}>
                        <CardHeader
                            title={<Typography variant='h6' >My Address</Typography>}
                        />
                        <CardContent sx={{ p: 0 }}>
                            <List >
                                {/* {console.log({userAddressDetails})} */}
                                {userAddressDetails ? userAddressDetails.map((data, index) => (
                                    <ListItem
                                        key={data.id}
                                        onMouseEnter={() => handleListItemMouseEnter(index)}
                                        onMouseLeave={() => handleListItemMouseLeave(index)}
                                        sx={{ p: 0, mb: 3 }}
                                    >
                                        <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                            <ListItemText>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.addressType}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.address}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.country?data.city:" "}, {data.state?data.state:" "}, {data.city?data.country:''}, {data.pincode?data.pincode:""}</Typography>
                                            </ListItemText>
                                            {/* {hoveredItems[index] && (
                                                <Box alignSelf={"center"}>
                                                    <Button onClick={() => openDeleteDialog('userContactDetails', data.id, index)}><Delete /></Button>
                                                </Box>
                                            )} */}
                                        </ListItemButton>
                                    </ListItem>
                                )) :
                                    [1, 2].map(d => <ListItem><Skeleton variant="text" sx={{ width: "100%", height: 40 }} /></ListItem>)
                                }
                            </List>
                            <Box textAlign={"center"}>
                                <Button startIcon={<Add />} onClick={openAddressDrawer} 
                                disabled={userAddressDetails?.length==2}
                                > ADD ADDRESS</Button>
                            </Box>
                        </CardContent>
                    </Card>
                    <SharedDrawer id="address">
                        <AddAddressDrawer parentId='address' cities={cities} states={states} countries={countries} />
                    </SharedDrawer>
                </Box>
                <Box>
                    <Card sx={{ mb: 3, p: 3, pb: 0 }}>
                        <CardHeader
                            title={<Typography variant='h6' >My Mobile Numbers</Typography>}
                        />
                        <CardContent sx={{ p: 0 }}>
                            <List >
                                {userContactDetails ? userContactDetails.map((data, index) => (
                                    <ListItem
                                        key={data.id}
                                        onMouseEnter={() => handleListItemMouseEnter(index)}
                                        onMouseLeave={() => handleListItemMouseLeave(index)}
                                        sx={{ p: 0, mb: 3 }}
                                    >
                                        <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                            <ListItemText>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.contactType}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.contactNo}</Typography>
                                            </ListItemText>
                                            {/* {hoveredItems[index] && (
                                                <Box alignSelf={"center"}>
                                                    <Button onClick={() => openDeleteDialog('userContactDetails', data.id, index)}><Delete /></Button>
                                                </Box>
                                            )} */}
                                        </ListItemButton>
                                    </ListItem>
                                )) :
                                    [1, 2].map(d => <ListItem><Skeleton variant="text" sx={{ width: "100%", height: 40 }} /></ListItem>)
                                }
                            </List>
                            <Box textAlign={"center"}>
                                <Button startIcon={<Add />} onClick={openMobileDrawer}> ADD MOBILE NUMBER</Button>
                            </Box>
                        </CardContent>
                    </Card>
                    <SharedDrawer id="mobile">
                        <AddContactDrawer parentId='mobile' drawerHeading={"Add Mobile Number"} />
                    </SharedDrawer>
                </Box>
                <Box>
                    <Card sx={{ mb: 3, p: 3, pb: 0 }}>
                        <CardHeader
                            title={<Typography variant='h6' >My Email Address</Typography>}
                        />
                        <CardContent sx={{ p: 0 }}>
                            <List >
                                {userEmailDetails ? userEmailDetails.map((data, index) => <ListItem
                                    onMouseEnter={() => handleEmailListItemMouseEnter(index)}
                                    onMouseLeave={() => handleEmailListItemMouseLeave(index)}
                                    sx={{ p: 0, mb: 3 }} key={index}>
                                    <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                        <ListItemText>
                                            <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.emailType}</Typography>
                                            <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.email}</Typography>
                                        </ListItemText>
                                        {/* {hoveredEmailItems[index] && (
                                            <Box alignSelf={"center"}>
                                                <Button ><Edit onClick={() => { openEditEmailDrawer(data, index) }} /></Button>
                                                <Button onClick={() => openDeleteDialog('userEmailDetails', data.id, index)} ><Delete /></Button>
                                            </Box>)} */}
                                    </ListItemButton>
                                </ListItem>)
                                    :
                                    [1, 2].map(d => <ListItem><Skeleton variant="text" sx={{ width: "100%", height: 40 }} /></ListItem>)
                                }
                            </List>
                            <Box textAlign={"center"}>
                                <Button startIcon={<Add />} onClick={openEmailDrawer}> ADD EMAIL</Button>
                            </Box>
                        </CardContent>
                    </Card>
                    <SharedDrawer id="email">
                        <AddEmailDrawer parentId='email' currentEditEmailIndex={currentEditEmailIndex} />
                    </SharedDrawer>
                    <SharedDialog id="deleteDialog">
                        <Box pb="1rem">
                            <DialogTitle id="alert-dialog-title">
                                {"Delete"}
                            </DialogTitle>
                            <DialogContent sx={{ padding: '16px 24px' }}>
                                <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                    This will be deleted forever and you won't be able to restore it.
                                    {/* {methodsAndState.dynamicDeleteMessage} */}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ padding: '16px 24px' }}>
                                <Button onClick={() => dispatch(closeDialog('deleteDialog'))}>
                                    CANCEL
                                </Button>
                                <Button onClick={deleteHandler} color='primary' variant="contained" >YES, DELETE</Button>
                            </DialogActions>
                        </Box>
                    </SharedDialog>
                </Box>
            </Box>
        </Box>
    )
}

export default ContactDetail