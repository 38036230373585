import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Grid, Stack, Dialog, DialogContent, CardHeader, IconButton, FormControlLabel, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { AccountCircle, Add, Delete, Edit, MoreVert } from '@mui/icons-material';
import ApiService from '../../utils/ApiService';


const DeviceSigin = ({ readOnly }) => {
    const [deviceList, setDeviceList] = useState([]);
    const [hovered, setHovered] = useState(false);

    useEffect(function () {
        ApiService.get('deviceSignIn').then(res => {
            let response = res.data
            if (response) {
                setDeviceList(response)
            }
        }).catch((error) => {
            console.log(error);
            // dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }, [])

    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Device Sign-ins</Typography>
                    </Box>
                </Stack>
                <Box>
                    <Card sx={{ mb: 3, p: 3, pb: 0 }}>
                        <CardContent sx={{ p: 0 }}>
                            <List >
                                {deviceList.length>0 &&deviceList.map((data) =>
                                    <ListItem onMouseEnter={() => setHovered(true)}
                                        onMouseLeave={() => setHovered(false)} sx={{ p: 0, mb: 3 }} >
                                        <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                            <ListItemText>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data?.name}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data?.access_token_id}</Typography>
                                            </ListItemText>
                                            {/* {hovered && ( */}
                                            <Box alignSelf={"center"}>
                                               {data.current_token.id=== data.access_token_id &&<Typography variant='caption' color="primary">Current Session</Typography>}
                                            </Box>
                                            {/* )} */}
                                        </ListItemButton>
                                    </ListItem>)
                                }
                                <ListItem onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)} sx={{ p: 0, mb: 3 }} >
                                    <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                        <ListItemText>
                                            <Typography variant='body1' fontWeight="var(--font-weight-5)">Mobile</Typography>
                                            <Typography variant='body2' fontWeight="var(--font-weight-4)">Ghaziabad, Uttar Pradesh, India</Typography>
                                        </ListItemText>
                                        {hovered && (
                                            <Box alignSelf={"center"}>
                                                <Button ><Delete /></Button>
                                            </Box>)}
                                    </ListItemButton>
                                </ListItem>
                            </List>

                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    )
}

export default DeviceSigin