import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import { Box, Grid, Typography, Button, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import ApiService from "../../utils/ApiService";
import { setSnackBar } from "../../features/snakebar/snakebar";

let tempBI = BasicInformationData
localStorage.setItem("tempBI", JSON.stringify(tempBI))

//Tab Panels Code
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

//Component code starts here
const AddDocumentDrawer = ({ id, parentId, currentEditDocumentIndex }) => {
    const userData = useSelector((state) => state?.user?.userListName);
    const userDocumentData = useSelector((state) => state?.drawer?.data); // {} by default

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        dispatch(closeDrawer(parentId));
    };

    let documentDetailsModel = [
        { label: 'Document Type', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }] },
        { label: 'Upload Document', type: "uploadFile", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }] },
    ];
    const documentDetailsHelper = new FormHelper({ model: documentDetailsModel, id: 'personalDetails' });

    const [candidateDetailsHelperData, setCandidateDetailsHelperData] = useState(documentDetailsHelper);

    const addContactDetail = () => {
        let dataCandidateDetalsHelper = candidateDetailsHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);
        console.log({ dataCandidateDetalsHelper })
        if (currentEditDocumentIndex == -1) {

            let formData = new FormData();
            formData.append('type', candidateDetailsHelperData['Document Type']);
            formData.append('file: ', candidateDetailsHelperData['Upload Document']);
            formData.append('userId', userData.userId);
            ApiService.postAdopter('userDocumnetsUpdate', formData).then((res) => {
                console.log({ res })
            }).catch((error) => {
                console.log(error);
                dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            })
        } else {
            let formData = {};
            formData['type'] = candidateDetailsHelperData['Document Type']
            formData['file'] = candidateDetailsHelperData['Upload Document']
            formData['userId'] = userData.userId

            ApiService.post('userDocumnetsUpdate', formData, userDocumentData.id).then((res) => {
                console.log({ res })
            }).catch((error) => {
                console.log(error);
                dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            })
        }
    }
    useEffect(() => {
        console.log("Shweta needs this", { userDocumentData });
        if (userDocumentData) {

            documentDetailsHelper.set({
                'Document Type': userDocumentData.type,
                'Upload Documen': userDocumentData.Image
            }, () => {
                // personalDetailsHelper.model = updatedModel;
            })
        }
    }, [userDocumentData])
    return (<>
        <Stack sx={{ backgroundColor: "var(--color-lightblue)", mb: 'var(--equal-hw1)', }}>
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Box pl='1rem' display='flex'>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >{currentEditDocumentIndex == -1 ? "Add Document Details" : "Edit Document Details"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                            <CloseIcon sx={{ color: 'var(--color-black)' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
        <Box sx={{ height: "90vh", overflowY: "auto" }}>
            <Box sx={{ width: 1 }}>
                <Box component="form" p={2} sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setCandidateDetailsHelperData(data) }} {...documentDetailsHelper.model} />
                </Box>
                <Box display={"flex"} sx={{ p: 2 }}>
                    <Button variant="contained" sx={{ mx: 1 }} onClick={addContactDetail} ><Typography variant="body2">{currentEditDocumentIndex == -1 ? "Add" : "Edit"}</Typography></Button>
                    <Button variant="outlined" sx={{ mx: 1 }} ><Typography variant="body2">CANCEL</Typography></Button>
                </Box>
            </Box>
        </Box>
    </>)
}

export default AddDocumentDrawer;